import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {AiOutlineSearch,AiOutlineClose} from 'react-icons/ai'
import Flushdoor from '../images/collection/doors/flush_doors/flush_door.png'
import {RiShareForwardFill} from 'react-icons/ri';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { app_url } from '../App';
import toast, { Toaster } from 'react-hot-toast';

function ProductList() {

  const navigator = useNavigate()  
  const {state} = useLocation()

  const {pathname} = useLocation()
  console.log("state : ",pathname)
  let path = pathname?.split('/')
  console.log("path",path)

const [all_data,setall_data] = useState([])
const [data,setdata] = useState([])
const [search,setsearch] = useState('')

useEffect(()=>{
  setdata(state)
  setall_data(state)
},[])

  function handlechange(e){
    let value = e.target.value
    setsearch(value)
    let datas = all_data.filter((f)=>JSON.stringify(f?.name)?.replace('_',' ')?.toLowerCase()?.includes(value))
    setdata(datas)
  }

  function openExternalLink(v){
    const url = 'https://apps.apple.com/in/app/wudgres/id6449000418'
    const url1 = 'https://play.google.com/store/apps/details?id=com.wudgresreal'

    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }
  }
  return (
    <div className='relative mx-5 md:mx-[70px] py-24'>
      <Toaster />
        
        {path[2] !== 'flush_doors'&& <>
        <div className='border relative focus:ring-0 focus:outline-none p-2 w-[90%] md:w-[40%] ml-[5%] md:ml-[30%] rounded-[20px]' >
         <input type="text" value={search} onChange={handlechange} placeholder='search by design name'  className='placeholder:text-gray-300 placeholder:text-[13px] text-[13px] focus:ring-0 focus:outline-none  w-[90%] ml-6'/> 
         <AiOutlineSearch size={20} className='absolute left-1 top-3 ml-1 text-gray-300' />
         <AiOutlineClose onClick={()=>{setsearch('');setdata(all_data)}} size={15} className='cursor-pointer absolute right-4 top-3 ml-1 text-gray-400' />
         </div>
        <div className='grid place-items-center grid-cols-1 gap-2 mt-10 md:grid-cols-3'>
            {data?.map((v,i)=>(
                <div onClick={()=>navigator('detail',{state:v})}   className={`group relative md:my-5  ${i > 4 ? 'mt-[0px]' : '-mt-[0px]'} `}>
                <img data-aos="fade-up" className={`md:h-[85vh] cursor-pointer w-[100%] object-contain `}  src={v?.img} alt='_img'/>
                <h6 className='text-center text-[18px] mt-2 font_thicksss uppercase'>{v?.name?.replace('_',' ')}</h6>
                <h6 className='text-[10px] group-hover:transition group-hover:transition-all group-hover:ease-in hidden group-hover:duration-700 group-hover:block absolute  leading-tight w-[100%] bottom-[6%] bg-black bg-opacity-80 text-white p-3  '>Click on the door to see the detail information of the product</h6>
                </div>
            ))}
        </div> 
        </>}

        {path[2] === 'flush_doors'&& <>
      
        <div className='w-[90%] relative md:w-[90%] mx-auto' >

        <CopyToClipboard text={`${app_url}${pathname}?product=${state?.name?.toLowerCase()}`} onCopy={()=>toast.success("URL Copied Successfully!")}>
        <span className='group hover:text-[#cbab75] cursor-pointer flex items-center absolute -top-20 right-0 p-1 flex-col md:flex-row bg-black sm:border md:border-none sm:border-[#ddd] md:bg-white'><RiShareForwardFill className='p-1 text-white md:text-black group-hover:text-[#cbab75]' size={30}/>  <span className='text-white block text-[10px] md:text-black group-hover:text-[#cbab75] font-[600]'>Share</span></span>
        </CopyToClipboard>

        <h1 className='text-[40px] md:text-[65px] text-[#cbab75] absolute right-0 -top-10 font_thicker'>FLUSH DOORS</h1>
        {/* <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[7%] -top-[18%]  md:-left-[6%] md:-top-[24%]'>FLUSH DOORS </h6> */}

        
        <div className='md:flex items-start'>
        <div className='w-full pt-10 pl-5 md:pt-0 md:pl-0 md:w-[80%]'>
            <img className='w-[100%]  md:h-[70vh] object-contain' src={Flushdoor} />
        </div>
        <div className='w-[90%] md:ml-20 mt-10  mx-auto '>
        <div className='flex my-2'>
        <h6 className='text-[13px] font_section1 uppercase min-w-[100px] max-w-[100px]'>Descripton </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
        <div className='ml-2 font_section1  items-center'>
            <h6 className='text-[13px] uppercase text-[#7d7d7d] font-[400]  mb-2'>WudGres Flush Doors are simple interior and exterior doors that feature clean facing on both sides of the construction. While the flush door is more closely associated with function rather than style, this plain sort of door lends itself well to a number of room designs where the desire is to highlight other elements in the space. Doors of this type will have a solid core. The typical Flush doors is manufactured by using two sections of plywood that are attached to each side of wooden frame. While the interior of the door is made of solid hardwood battens.</h6>
            <h6 className='text-[13px] uppercase text-[#7d7d7d] font-[400]  mb-2'>The frames in WudGres flush doors are of 100% Seasoned Hardwood. These flush doors are made in Boiling Water Resistant (BWR) grade, duly bonded with Fortified Phenol Formaldehyde Synthetic Resin.</h6>
        </div>  
        </div>


        <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>SERIES </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'>Flush Door </h6>
                </div>
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DESIGN </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'>RAW Door </h6>
                </div>

                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'> FACE VENEER </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'> MAKHAI </h6>
                </div>

                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] uppercase max-w-[100px]'>Wood filling  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>banyan </h6>
                </div>


             

                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>SIZE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                     <div className='ml-0'>
                     {state?.thickness !== '' &&   
                     <h6 className='text-[13px] md:text-[13px]  mb-1 ml-2 text-[#7d7d7d]'>THICKNESS (MM) : 32, 35, 38  </h6>}
                     {state?.height !== '' &&
                     <h6 className='text-[13px] md:text-[13px] mb-1 ml-2 text-[#7d7d7d]'>HEIGHT (INCHES) : 72, 75, 78, 81, 84, 90, 96 </h6>}
                    {state?.width !== '' &&
                     <h6 className='text-[13px] md:text-[13px] mb-1 ml-2 text-[#7d7d7d]'><span>WIDTH (INCHES)</span> : 27, 30, 32, 33, 36, 38, 42, 48 </h6>}
                     <h6 className='text-[12px] md:text-[13px] ml-2 text-[#7d7d7d]'>* Customized size can be made, cost calculated for next standard size </h6>
                    </div>
                </div>
                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>FINISH </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>RAW</h6>
                </div>

                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>USAGE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'>INTERIOR & EXTERIOR</h6>
                </div>
                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>TYPE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>FLUSH DOOR, WOOD FILLED FLUSH DOOR, RAW DOOR</h6>
                </div>

                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>WUDGRES APP </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'> To check the price of the door ,download wudgres app</h6>
                </div>

{/* 
        <h6 className='text-[13px] uppercase underline mt-4 font-[800]'>OUTSTANDING FEATURES</h6>
        <ul className='list-disc mt-2 ml-4'>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'>Excellent strength, stiffness & impact resistence.</li>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'>Exceptional durability against moisture and cyclic change in weather.</li>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'> Unique dimensional stability.</li>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'> No wrapping & delamination when subject to changes in atmospheric condition.</li>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'> Anti-insect Toxicant mixed with adhesive make doors - Borer & Termite Resistant.</li>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'> Great consistency in quality - assured by standardized industrial processing techniques and quality control systems.</li>
            <li className='text-[14px] font_thick font-[400] px-1 py-1'> Unique dimensional stability.</li>
        </ul>  */}
        
        
        <h6 className='text-[14px] uppercase underline mt-4 font-[800]'>APPLICATIONS</h6>
        <h6 className='text-[14px] font_thick mt-2'>Suitable for Interior and exterior use such as Main door, Bathrooms, Toilets, Kitchen, Balcony doors etc.</h6>

        <div className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

              </div>

        </div>



        </div>
        </div>
        </>}
    </div>
  )
}

export default ProductList