import React, {useState } from 'react';
// import { Carousel } from 'antd';
import './Home.css';
import CountUp from 'react-countup';
import Mobile1 from '../images/mobile1.jpg'
import wpcDoor from '../images/wpc-home.png'
import BlackBoard from '../images/board.jpeg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {IoIosArrowBack,IoIosArrowForward} from 'react-icons/io';
import about_side from '../images/about_side.webp'


// materials 
import metalic_grandillo from '../images/collection/material/metalic-grandillo.png'
import metalic_sugar_maple from '../images/collection/material/metalic-sugar-maple.png'
import royale_rio_rosewood from '../images/collection/material/royale-rio-rosewood.png'
import royale_fresh_jio from '../images/collection/material/royale-fresh-jio.png'
import king_teak_crown_vrt from '../images/collection/material/king-teak-crown-vrt.png'
import king_teak_crown_hz from '../images/collection/material/king-teak-crown-hz.png'
import american_walnut_crwn from '../images/collection/material/american-walnut-crwn.png'
import dark_fantacy from '../images/collection/material/dark-fantacy.png'
import brazilian_limba from '../images/collection/material/brazilian-limba.png'
import marmor from '../images/collection/material/marmor.png'
import slato_grey from '../images/collection/material/slato-grey.png'
import eucalyptus_ivory from '../images/collection/material/eucalyptus-ivory.png'
import eucalyptus_sand from '../images/collection/material/eucalyptus-sand.png'
import eucalyptus_coal from '../images/collection/material/eucalyptus-coal.png'
import earthy_noyer from '../images/collection/material/earthy-noyer.png'
import honne_higlo from '../images/collection/material/honne-higlo.png'
import golden_crown from '../images/collection/material/golden-crown.png'
import prestine from '../images/collection/material/prestine.png'
import spectora from '../images/collection/material/spectora.png'
import walnut_burst from '../images/collection/material/walnut_burst.png'
import woody_dogler_oak from '../images/collection/material/woody-dogler-oak.png'
import natural_burma_teak from '../images/collection/material/natural-burma-teak.png'
import saw_cut_white from '../images/collection/material/saw-cut-white.png'
import brown_walnut from '../images/collection/material/brown-walnut.png'
import reaper_grey from '../images/collection/material/reaper-grey.png'
import reaper_pearl from '../images/collection/material/reaper-pearl.png'
import reaper_brown from '../images/collection/material/reaper-brown.png'
import koro_rosewud from '../images/collection/material/koro-rosewud.png'
import cherry_teak from '../images/collection/material/cherry_teak.png'
import cherry_wood from '../images/collection/material/cherry-wood.png'
import vintage_grano_hz from '../images/collection/material/vintage-grano-hz.png'
import rose_grandis_hz from '../images/collection/material/rose-grandis-hz.png'
import teak_grandis_hz from '../images/collection/material/teak-grandis-hz.png'
import natural_teak_ca from '../images/collection/material/natural-teak-ca.png'
import andhra_teak_ca from '../images/collection/material/andhra-teak-ca.png'
import rose_wood_ca from '../images/collection/material/rosewood-ca.png'
import mahogony_cb from '../images/collection/material/mahogony-cb.png'
import natural_wenge_cc from '../images/collection/material/natural-wenge-cc.png'
import jungle_teak_cc from '../images/collection/material/Jungle-teak-cc.png'


import flower_bg from '../images/flower.png'
import { useNavigate } from 'react-router-dom';

// banners 
import banner1 from '../images/banner/1.jpg'
import banner2 from '../images/banner/2.jpg'
import banner3 from '../images/banner/3.jpg'
import banner4 from '../images/banner/4.png'
import banner5 from '../images/banner/5.jpg'


import door from '../images/door.jpeg'
import frames from '../images/frames.jpeg'
import window1 from '../images/window.webp'

function Home() {


  const navigator = useNavigate()

  const [index,setindex] = useState(0)
  const images = [banner1,banner2,banner3,banner4,banner5]


  // banner2,banner3,banner4,banner5
  function openExternalLink(v){
    const url = 'https://apps.apple.com/in/app/wudgres/id6449000418'
    const url1 = 'https://play.google.com/store/apps/details?id=com.wudgresreal'

    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }
  }

  function openExternalLink1(v){
    const url = '  https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+Master++Catalogu+2023+(1).pdf'
    const url1 = 'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+PL+2023+(1).pdf'

    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }
  }



  const material = [
     {
      id:1,
      name:'Metalic Grandillo',
      img:metalic_grandillo
     },
     {
      id:2,
      name:'Metalic Sugar Maple',
      img:metalic_sugar_maple
     },
     {
      id:3,
      name:'Royale Rio RoseWood',
      img:royale_rio_rosewood
     },
     {
      id:4,
      name:'Royale Fresh Jio',
      img:royale_fresh_jio
     },
     {
      id:5,
      name:'King Teak Crown - VRT',
      img:king_teak_crown_vrt
     },
     {
      id:6,
      name:'King Teak Crown - HZ',
      img:king_teak_crown_hz
     },
     {
      id:7,
      name:'American Walnut CRWN',
      img:american_walnut_crwn
     },
     {
      id:8,
      name:'Dark Fantacy',
      img:dark_fantacy
     },
     {
      id:9,
      name:'Brazilian Limba',
      img:brazilian_limba
     },
     {
      id:10,
      name:'Marmor',
      img:marmor
     },
     {
      id:11,
      name:'Slato Grey',
      img:slato_grey
     },
     {
      id:12,
      name:'Eucalyptus Ivory',
      img:eucalyptus_ivory
     },
     {
      id:13,
      name:'Eucalyptus Sand',
      img:eucalyptus_sand
     },
     {
      id:14,
      name:'Eucalyptus Coal',
      img:eucalyptus_coal
     },
     {
      id:15,
      name:'Earthy Noyer',
      img:earthy_noyer
     },
     {
      id:16,
      name:'Honne Higlo',
      img:honne_higlo
     },
     {
      id:17,
      name:'Golden Crown',
      img:golden_crown
     },
     {
      id:18,
      name:'Prestine',
      img:prestine
     },
     {
      id:19,
      name:'Spectora',
      img:spectora
     },
     {
      id:20,
      name:'Walnut Burst',
      img:walnut_burst
     },
     {
      id:21,
      name:'Woody Dogler Oak',
      img:woody_dogler_oak
     },
     {
      id:22,
      name:'Natural Burma Teak',
      img:natural_burma_teak
     },
     {
      id:23,
      name:'Saw Cut  White',
      img:saw_cut_white
     },
     {
      id:24,
      name:'Brown Walnut',
      img:brown_walnut
     },
     {
      id:25,
      name:'Reaper Grey',
      img:reaper_grey
     },
     {
      id:26,
      name:'Reaper Pearl',
      img:reaper_pearl
     },
     {
      id:27,
      name:'Reaper Brown',
      img:reaper_brown
     },
     {
      id:28,
      name:'Koro RoseWud',
      img:koro_rosewud
     },
     {
      id:29,
      name:'Cherry Teak',
      img:cherry_teak
     },
     {
      id:30,
      name:'Cherry Wood',
      img:cherry_wood
     },
     {
      id:31,
      name:'Metalic Grandillo',
      img:vintage_grano_hz
     },
     {
      id:32,
      name:'Rose Grandis HZ',
      img:rose_grandis_hz
     },
     {
      id:33,
      name:'Teak Grandis HZ',
      img:teak_grandis_hz
     },
     {
      id:34,
      name:'Natural Teak CA',
      img:natural_teak_ca
     },
     {
      id:35,
      name:'Andhra Teak CA',
      img:andhra_teak_ca
     },
     {
      id:36,
      name:'Rose Wood CA',
      img:rose_wood_ca
     },
     {
      id:37,
      name:'Mahogony CB',
      img:mahogony_cb
     },
     {
      id:38,
      name:'Natural Wenge CC',
      img:natural_wenge_cc
     },
     {
      id:39,
      name:'Jungle Teak CC',
      img:jungle_teak_cc
     }
  ]


  // console.log("material?.length",material.length)

  function downloadfile(v){
    let cateloug = 'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+Master++Catalogu+2023+(1).pdf'
    let pricelist = 'https://wudgres.s3.ap-south-1.amazonaws.com/WudGres+PL+2023+(1).pdf'

    if(v === '1'){
      window.open(cateloug, '_blank');
    }else if(v === '2'){
        window.open(pricelist, '_blank');
    }
  }

  return (
    <div className='w-full overflow-x-hidden z-10 sm:px-0 mx-auto relative'>


         <div className='relative z-10'>
          <Carousel 
            centerMode={true} 
            autoPlay={true} 
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            swipeable={true}
            autoFocus={true}
            interval={5000}
            centerSlidePercentage={100}
            showIndicators={false}
            className='relative bg-red-100 -z-0'
          
            onChange={(e)=>{
              setindex(e)
            }}
            
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-[35px] z-100 border-r-2 border-gray-100 bottom-0 bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowBack size={20} className="text-black" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${hasPrev ? "opacity-100" : "opacity-20"} absolute right-0 bottom-0 bg-white  flex justify-center items-center p-2  cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <IoIosArrowForward size={20} className="text-black" />
                </div>
              );
            }}
          
          >
              {images?.map((c,i)=>(
                  <div className='text-center  w-[100%] h-[35vh] md:h-[89vh]  overflow-auto z-1  mx-auto focus:outline-none focus:ring-0'>
                      <img src={c}  className='w-full  relative h-[35vh] md:h-[89vh] object-stretch md:object-cover focus:outline-none focus:ring-0 '/>
                      
                    

                    


                  </div>    
              ))}

                     


             

          </Carousel>

          <div  className={`${index == 0 ? 'absolute z-20 -bottom-10' : 'hidden z-20'} p-5 py-10  bg-[#f6f7f8] w-[75%] md:w-[30%]   left-[12%] md:left-[35%] pl-4`}>
                        <h6 className='text-[18px] text-center md:text-[20px] font_thick text-black'><span className='text-[#ef8013] font_bold'>Craftsmanship</span> Unparalleled</h6>
                        <h6 className='text-[11.5px] text-center md:text-[11.5px] leading-tight mt-2 opacity-70 font_thick'>Started Working on wood with chisel and hammer first in 1991 since then we have worked very hard to see the beautiful smile on the face of our millions of customers by making ultimate masterpieces for them.</h6>
                      </div>

                  <div className={`${index == 1 ? 'absolute z-40 -bottom-10' : 'hidden'} p-5 py-10 bg-[#f6f7f8] w-[75%] md:w-[30%]   left-[12%] md:left-[35%] pl-4`}>
                       <h6 className='text-[18px] text-center md:text-[24px] font_thick text-black'><span className='text-[#ef8013] font_bold'>Environment friendly </span>And </h6>
                       <h6 className='text-[18px] text-center md:text-[24px] font_thick text-black'><span className='font_bold'>That's WudGres</span></h6>
                       <h6 className='text-[11.5px] text-center md:text-[11.5px] mt-2 leading-tight text-black opacity-70'>Wood is part of everybody is life from making a cradle for the newborn to making a pyre or coffin for one who departed but as a responsible brand it is our duty minimise the use and wastage of timber. At WudGres we use the leftover pieces of wood as battens in Flush doors which is usually used as firewood. Thus giving the value and life to what was turning to Ash.</h6>
                      </div>

                      <div className={`${index == 2 ? 'absolute z-50 -bottom-10' : 'hidden z-20'} p-5 py-10 bg-[#f6f7f8] w-[75%] md:w-[30%]  left-[12%] md:left-[35%] pl-4`}>
                        <h6 className='text-[18px] text-center md:text-[24px] font_thick text-black'><span className='text-[#ef8013] font_bold'>Designs Range</span>That</h6>
                        <h6 className='text-[18px] text-center md:text-[24px] font_thick text-black'><span className='font_bold'>immense and borderless</span></h6>
                        <h6 className='text-[11.5px] text-center md:text-[11.5px] mt-2 leading-tight text-black opacity-70'>Anybody would like to have a best door for their beautiful home. We WudGres understand this, having said that we offer more than 500 designs in unlimited something to choose from and yes customising for even 1 door is possible only at WudGres</h6>
                      </div>

                      <div className={`${index == 3 ? 'absolute z-50 -bottom-10' : 'hidden z-20'} p-5 py-10 bg-[#f6f7f8] w-[75%] md:w-[30%]  left-[12%] md:left-[35%] pl-4`}>
                        <h6 className='text-[18px] text-center md:text-[24px]  font_thick text-black'><span className='text-[#ef8013] font_bold'>Why WudGres</span></h6>
                        <h6 className='text-[11.5px] text-center md:text-[11.5px] mt-2 leading-tight text-black opacity-70'>A Very important question everybody should be thinking and here we answer you.</h6>
                        <div className='flex items-center justify-center'>
                        <h6 onClick={()=>navigator('/about_us')} className='flex items-center justify-center transition w-24 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#000] py-1 px-3 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[11px]'>KNOW WHY</h6>     
                        </div>
                      </div>

                      <div className={`${index == 4 ? 'absolute z-50 -bottom-10' : 'hidden z-20'} p-5 py-10 bg-[#f6f7f8] w-[75%] md:w-[30%] left-[12%] md:left-[35%] pl-4`}>
                        <h6 className='text-[18px] text-center md:text-[24px] font_thick text-black'><span className='text-[#ef8013] font_bold'>Making thing easier</span></h6>
                        <h6 className='text-[11.5px] text-center md:text-[11.5px] mt-2 leading-tight text-black opacity-70'>Imagine trying to get a door of your choice from WudGres getting to see virtually at your place. Yes you heard it right and that is what you will be able to do by downloading WudGres App in your smartphone. You can also check for prices of products across the WudGres range it's available for both Android and IOS users.</h6>
                      </div>
          {/* <h1>Hello {index}</h1> */}
          {/* <div data-aos="fade-left" className='absolute p-5 py-10 bg-[#f6f7f8] w-[65%] md:w-[30%] -bottom-[0%] md:top-[28%] left-[5%] pl-4'> */}
              {/* <h6 className='text-[18px] md:text-[20px] font_thick text-black'><span className='text-[#ef8013] font_bold'>Craftsmanship</span> Unparalleled</h6>
              <h6 className='text-[12.5px] md:text-[12.5px] leading-tight mt-2 opacity-70 font_thick'>Started Working on wood with chisel and hammer first in 1991 since then we have worked very hard to see the beautiful smile on the face of our millions of customers by making ultimate masterpieces for them.</h6> */}

           {/* <Typist stdTypingDelay={5}>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>Environment friendly</span>And </h6>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>That's WudGres</span></h6>
              <h6 className='text-[12.5px] md:text-[15px] text-white opacity-70'>
                                    Wood is part of everybody is life from making a cradle for the newborn to making
                                    a pyre or coffin for one who departed but as a responsible brand it is our duty
                                    minimise the use and wastage of timber. At WudGres we use the leftover pieces
                                    of wood as battens in Flush doors which is usually used as firewood. Thus giving the
                                    value and life to what was turning to Ash.
                                </h6>
            </Typist>

            <Typist stdTypingDelay={5}>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>Designs Range</span>That</h6>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>immense and borderless</span></h6>
              <h6 className='text-[12.5px] md:text-[15px] text-white opacity-70'>
                                    
              Anybody would like to have a best door for their beautiful home. We WudGres
                                    understand this, having said that we offer more than 500 designs in unlimited
                                    something to choose from and yes customising for even 1 door is possible only at
                                    WudGres
                                
                                </h6>
            </Typist>

            <Typist stdTypingDelay={5}>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>Why WudGres</span></h6>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>immense and borderless</span></h6>
              <h6 className='text-[12.5px] md:text-[15px] text-white opacity-70'>
                                    
             
              A Very important question everybody should be thinking and here we answer you.

              <h6>KNOW WHY</h6>
                                
                                </h6>
            </Typist>

            <Typist stdTypingDelay={5}>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>Making thing easier</span></h6>
              <h6 className='text-[18px] md:text-[24px] font_thick text-white'><span className='text-[#cbab75] font_bold'>immense and borderless</span></h6>
              <h6 className='text-[12.5px] md:text-[15px] text-white opacity-70'>
                                    
             
              
              Imagine trying to get a door of your choice from WudGres getting to see
                                    virtually at your place. Yes you heard it right and that is what you will be
                                    able to do by downloading WudGres App in your smartphone. You can also check for
                                    prices
                                    of products across the WudGres range it's available for both Android and IOS
                                    users.
                                

                                
                                </h6>
            </Typist> */}

          {/* </div> */}
          </div> 
          
          <div className='border-b  bg-map bg-contain border-slate-100 z-10'>
         
          <div className='mx-auto md:flex md:items-center w-10/12 md:w-8/12 pb-20'>
             <div  className='flex items-center relative justify-center md:block md:min-w-[50%] md:w-[50%]'>
               <img data-aos="fade-left" src={about_side} className='w-[100%]  object-cover md:-ml-[100px] opacity-100 mt-20' />
             </div>

             <div className='md:min-w-[50%] md:w-[50%] mt-20 md:mt-10 z-100  relative  md:mt-0'>
                 <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[7%] -top-[18%]  md:-left-[10%] md:-top-[24%]'>About Us </h6>

                <h6  className='font_section1 font_bold z-50 pt-5 md:pt-0 md:mt-10 text-[16px] md:text-[22px] mb-4 text-[#000]'>A Recognized and a well <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Trusted</span> Company in India.</h6>
                <h6 className='text-[15px] font_section1 mx-auto md:text-[15px] mb-4 text-[#000]'>At <span className=' text-[#cbab75] font-[700]'> Diwani Group</span>, we have been making products where we make the best from left-over wood from so many Saw Mills which is usually used as firewood.</h6>
                <h6 className='text-[15px] font_section1 mx-auto md:text-[15px] mb-4 text-[#000]'> All the products made under WudGres are of international standards and made to last, so there is little or no need to replace them over time, which helps in saving on wood, which is again its replacement.</h6>
                <h6 onClick={()=>navigator('/about_us')} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[90px] text-center'>Read More</h6>
                {/* <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-40  text-slate-200 absolute  after:-z-10 -top-[14%] md:-left-[1%] md:-top-[25%]'>Woody </h6> */}
              </div>
          </div>  

            <div className=' py-5 border-t border-gray-100  bg-blend-lighter bg-center bg-fixed'>

              <div className='grid w-10/12 text-[#000] md:w-8/12 mx-auto grid-cols-2 md:grid-cols-4 justify-between  my-10 mb-15 py-5 md:p-5'>

                <div className='border-r'>
                    <h6 className='text-center font-[800] text-[30px]'><CountUp duration={10} end={3} /></h6>
                    <h6 className='text-center font_section1 text-[13px] font-[400]'>BRANDS OWNED BY DIWANI</h6>
                </div>
                <div className='md:border-r border-slate-200'>
                    <h6 className='text-center font-[800] text-[30px]'><CountUp duration={10} end={16} /></h6>
                    <h6 className='text-center font_section1 text-[13px] font-[400]'>YEAR IN BUISNESS</h6>
                </div>
                <div className='border-r mt-5 md:mt-0 border-slate-200'>
                    <h6 className='text-center font-[800] text-[30px]'><CountUp duration={10} end={200} /> +</h6>
                    <h6 className='text-center font_section1 text-[13px] font-[400]'>NUMBER OF PRODUCTS</h6>
                </div>
                <div className='md:border-r border-slate-200 mt-5 md:mt-0 md:border-none'>
                    <h6 className='text-center font-[800] text-[30px]'><CountUp duration={10} separator="," end={1298} /> +</h6>
                    <h6 className='text-center font_section1 text-[13px] font-[400]'>SATISFIED CUSTOMERS</h6>
                </div>
              </div>
            </div>
          </div>
         
          {/* Our Services  */}
          <div className='bg-typography  bg-contain bg-gray-50 py-24 '>
          <div className='relative '>
          <h6 className='font_thicker text-[60px] leading-tight  md:text-[90px] z-0  text-[#cbab75] mb-10 w-full text-center  after:-z-10 uppercase -top-[175%]  md:-left-[1%] md:-top-[255%]'>What we make </h6>


         

          </div>
          <div className='w-full  mx-auto'>
         
             

             <div className='w-[80%] mt-16 md:mt-0 grid grid-cols-1 gap-2  md:grid-cols-5 justify-evenly mx-auto'>

            <div data-aos="fade-up" onClick={()=>navigator('/products/doors')} className='group bg-[#cbab75] h-[140px]  group cursor-pointer shadow-md  md:mb-0 w-full p-2 mx-2 flex items-center justify-center'>
            <h6 className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>Doors</h6>
            </div>
            <div data-aos="fade-up" onClick={()=>navigator('/products/wpc')} className='group bg-[#cbab75] h-[140px] w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
            <h6  className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>WPC</h6>
            </div>
            <div data-aos="fade-up" onClick={()=>navigator('/products/shutters')} className='group bg-[#cbab75] h-[140px]  w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
            <h6  className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>Window Shutters</h6>
            </div>
            <div data-aos="fade-up" onClick={()=>navigator('/products/board')} className='group bg-[#cbab75] h-[140px]  w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
            <h6  className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>Plywood & Block Board</h6>
            </div>
            <div data-aos="fade-up" onClick={()=>navigator('/products/engineered')} className='group bg-[#cbab75] h-[140px]  w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
            <h6  className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>ENG.. WOOD FRAMES</h6>
            </div>
            

</div>


            
          </div>


          </div>

          {/* Doors Section  */}
          <div className=' pt-6'>  
          <div className='my-20 w-11/12  md:w-8/12  mx-auto'>
            <div className='block md:flex items-center align-center justify-center '>
              <img data-aos="fade-left"  className='md:mt-0 w-full md:-ml-[11%] md:w-[100%] h-[300px] md:h-[360px] object-cover md:object-cover' src={door} alt="_mobile_app" />
              <div className='mt-0 relative mt-10 md:mt-0 ml-0 md:ml-[90px]'>
              <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[25%] -top-[30%]  md:-left-[26%] md:-top-[9%]'>Doors </h6>
              <h6 className='font-[400] font_thick mt-[80px] md:mt-18  text-[13px] md:text-[13px]'>Bringing you the varieties of doors for your dream home provided by wudgres ...</h6>
              
              <h6  className='font_section1 font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>Extensive Design with  <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Limitless</span> Range and Design ..</h6>

                <h3 className='text-[15px] font_thick '>  Classic designs of past to designs of modern world and then the design of future, explore the extensive range and limitless designs from WudGres.</h3>

                <h6 className='text-[15px]  mt-2 font_thick '>More than <span className='font_bold'> {material?.length}</span> varieties or material to make a high quality customizable door for your home. </h6>
                <div className='mt-5'>
                <h6 onClick={()=>navigator('/products/doors',{state:'doors'})} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>View Collection</h6>
                </div>
              </div>
              </div>
          </div>
          </div>

          {/* WPC Section  */}
          <div className='bg-gray-50 py-[35px]'>

            <div className='my-12 relative md:my-12  w-11/12  md:w-10/12  mx-auto'>
          <div className='block md:flex  align-center'>
            <div className='mt-10 md:w-[44%] relative md:mt-0  md:ml-5'>
              <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[30%] -top-[18%]  md:-left-[30%] md:-top-[16%]'>WPC </h6>

              <h6 className='font-[400] font_thick  md:mt-[50px]  text-[13px] md:text-[13px]'>Bringing you the WPC board high strength and long-lasting material  by wudgres .</h6>
              <h6  className='font_section1 relative font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>A Truewood <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Alternative.</span> WPC Board Provide By Wudgres.</h6>

              <h3 className='text-[15px]  font_thick mt-2 '> The need for Timber has been growing rapidly leading to less of green cover. Also the fact is the use of timber cannot be stopped completely but the way has to be found to minimise its use for the sake of our next generations. Keeping this in mind Wudgres introduces Woodlord - WPC doors, WPC frames and WPC plywood and several other products made from sawdust and virgin recycled plastic composites and other bonding chemicals. Woodlord WPC 100% recyclable makes it environment friendly product.</h3>
              <h3 className='text-[13px]  font_thick mt-3 '> Welcome to Woodlord A timber alternative of genuine value for creating lasting and beautiful spaces.</h3>

              <div className='mt-5'>
              <h6 onClick={()=>navigator('/products/wpc',{state:'wpc'})} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>View Collection</h6>

              </div>
            </div>
            <img  data-aos="fade-right" className='mt-10 md:mt-0 w-full md:w-[50%] h-[240px] md:-ml-[30px] md:h-[250px] md:object-contain' src={wpcDoor} alt="_mobile_app" />

            </div>
            </div>
          </div>

            {/* Window shutters Section  */}
          <div className=' pt-6'>
          <div className='my-20 w-11/12  md:w-8/12  mx-auto'>
            <div className='block md:flex align-center justify-center '>
              <img data-aos="fade-left" className='md:mt-0 w-full md:-ml-[11%] md:w-[51.5%] h-[300px] md:h-[360px] object-cover md:object-cover' src={window1} alt="_mobile_app" />
              <div className='mt-0 relative mt-10 md:mt-0 ml-0 md:ml-[90px]'>
              <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[16%] -top-[30%]  md:-left-[14%] md:-top-[8%]'>Window </h6>
              <h6 className='font-[400] font_thick mt-[90px]  text-[13px] md:text-[13px]'>Bringing you the varieties of doors for your dream home provided by wudgres ...</h6>
              
              <h6  className='font_section1 font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>Extensive Design with  <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Limitless</span> Range and Design ..</h6>

                <h3 className='text-[15px] font_thick '>  Classic designs of past to designs of modern world and then the design of future, explore the extensive range and limitless designs from WudGres.</h3>

                <h6 className='text-[15px]  mt-2 font_thick '>More than <span className='font_bold'> {material?.length}</span> varieties or material to make a high quality customizable door for your home. </h6>
                <div className='mt-5'>
              <h6 onClick={()=>navigator('/products/shutters',{state:'shutters'})} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>View Collection</h6>
                </div>
              </div>
              </div>
          </div>
          </div>

          {/* BLACK Board  */}
          <div className='bg-gray-50 py-[35px]'>


          <div className='md:my-16 mb-20 w-11/12  md:w-10/12  mx-auto'>
            <div className='block md:flex align-center justify-center '>
              <div className='mt-10 md:w-[40%] relative md:mt-0 ml-0 md:-ml-6'>
              <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[26%] -top-[36%]  md:-left-[24%] md:-top-[8%]'>Board </h6>

              <h6 className='font-[400] mt-24 font_thick  text-[13px] md:text-[13px]'>Bringing you the Black board with high strength and long-lasting resistance  by wudgres .</h6>

              <h6  className='font_section1  relative font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>Black Board & Ply Wood that are <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> STURDY</span> That'S Made The Last ..</h6>

                <h3 className='text-[13px] md:text-[15px] font_thick '>  Strong, tough, long lasting are few of many words you can use to describe the plywood and block board from WudGres. Made from carefully selected hard wood species and pine wood to make the sturdy that is made to last.</h3>
                <div className='mt-5'>
                   <h6 onClick={()=>navigator('/products/board',{state:'board'})} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>View Collection</h6>
                </div>
              </div>
              <img data-aos="fade-right" className='mt-10 md:mt-0 w-full md:w-[50%] h-[220px] md:h-[280px] md:ml-[6%] md:object-contain' src={BlackBoard} alt="_mobile_app" />

              </div>
          </div>
          </div>

          {/* Frames */}
          <div className=' pt-6'>
          <div className='my-20 w-11/12  md:w-8/12  mx-auto'>
            <div className='block md:flex align-center justify-center '>
              <img  data-aos="fade-left" className='md:mt-0 w-full md:-ml-[11%] md:w-[51.5%] h-[300px] md:h-[360px] object-cover md:object-cover' src={frames} alt="_mobile_app" />
              <div className='mt-0 relative mt-10 md:mt-0 ml-0 md:ml-[90px]'>
              <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[18%] -top-[22%]  md:-left-[18%] md:-top-[8%]'>Frames </h6>
              <h6 className='font-[400] font_thick mt-24  text-[13px] md:text-[13px]'>Bringing you the varieties of  wpc doors and wpc frames which are provided by wudgres ...</h6>
              
              <h6  className='font_section1 font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>Wpc Frames And Doors   <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Provided</span> for your wood works ..</h6>

                <h3 className='text-[15px] font_thick '>  The need for Timber has been growing rapidly, leading to less of Green Cover. Also the fact is the use of Timber cannot be stopped completely, but the way has to be found to minimise its use for the sake of our next generations. Keeping this in mind WUDGRES introduces WPC Doors, WPC Frames, WPC Boards & several other products made from saw dust (wood powder) & virgin or recycled plastic composites. WUDGRES WPC is durable and long lasting.</h3>

                <div className='mt-5'>
                <h6 onClick={()=>navigator('/products/engineered',{state:'engineered'})} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>View Collection</h6>
                </div>
              </div>
              </div>
          </div>
          </div>
          <div>


          {/* Brocher Download */}
          <div className='bg-gray-50 bg-background bg-cover md:bg-contain bg-no-repeat bg-right-top h-[200px]  w-full md:h-[300px]'>
          

            <div className=' px-[5%] bg-gray-50 py-10 md:px-[10%] md:w-[65%] md:py-20'>
                <h6  className='font_section1 relative font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>Looking for an <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Unique</span> Doors!.</h6>
                <h6 className='text-[13px] md:text-[15px] mt-2 font-[300] font_thick'>You will love our wide range of products from Wudgres. we will create each product by keeping environment eco friendly in mind.</h6>

              <div className='mt-5 flex items-center'>
                <h6 onClick={()=>openExternalLink1(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[140px] text-center'>Download cateloug</h6>
                <h6 onClick={()=>openExternalLink1(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[140px] ml-2 text-center'>Download PriceList</h6>

                {/* <span onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-0.5 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] p-5'>Download cateloug</span> */}
                {/* <span onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-0.5 px-2.5 cursor-pointer ml-2 hover:bg-black hover:text-white font-[300] font_thick text-[12px]'>Download PriceList</span> */}
              </div>
            </div>

          </div>

          {/* Mobile App */}
          <div className=' md:my-2 w-11/12 mb-0 md:w-8/12  mx-auto'>
          <div className='block md:flex  justify-center'>
            <div className='relative w-[100%]'>
            <img  data-aos="fade-up" className=' md:mt-0 w-[100%]  mt-5 md:mt-0 h-[400px] md:h-[500px] object-contain cursor-pointer transition delay-100 duration-400 ease-in hover:scale-105 z-10' src={Mobile1} alt="_mobile_app" />
            <img  className=' md:mt-0 w-full md:w-[70%] mt-5 md:mt-0 h-[250px] md:h-[150px] object-contain cursor-pointer absolute -left-20 top-0 z-0' src={flower_bg} alt="_mobile_app" />
            <img  className=' md:mt-0 w-full md:w-[70%] mt-5 md:mt-0 h-[250px] md:h-[150px] object-contain cursor-pointer absolute -right-20 bottom-0 z-0' src={flower_bg} alt="_mobile_app" />

            </div>
            <div className='-mt-10 md:mt-0 relative md:ml-5 mt-5 md:mt-0 pb-10'>
            <h6 className='font_thicker text-[60px] md:text-[90px] z-0 opacity-100  text-[#cbab75] absolute w-full text-center  after:-z-10 -left-[8%] -top-[30%]  md:-left-[17%] md:top-[8%]'>Download </h6>

              <h3 className='text-[13px] mt-40 font_thick'> First Time Ever  <span >the door purchasing app by Wudgres...</span></h3>
              <h6  className='font_section1 font_bold z-50 text-[16px] md:text-[22px] mb-4 text-[#000]'>Make your doors choice Easyer  then before with <span className='z-100   relative after:bg-[#cbab75] after:absolute after:h-[7px] after:left-[0px] after:bottom-[5px] after:-z-10 after:w-full after:bg-[#aaa]'> Wudgres</span> Company in India.</h6>

              <h6 className='text-[13px] md:text-[15px] mt-2 font-[300] font_thick'>With a Huge Portfolio and multiple options and sub-options its always difficult to check the prices and come up with the exact rate for a particular size. wudgres app makes it easier for everyone to find the exact price of the product in detail. Now it's available for both Android and ios</h6>
              <h6 className='text-[13px] md:text-[15px] mt-2 font-[300] font_thick'>The app which is powered by  <span className='font_bold'>wudgres</span>.</h6>


              <div className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

              </div>
            </div>
            </div>
          </div>
          </div>
    </div>
  )
}

export default Home