import React, { useEffect, useState } from 'react'
import { useLocation,useSearchParams } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { app_url } from '../App';
import toast, { Toaster } from 'react-hot-toast';
import {RiShareForwardFill} from 'react-icons/ri';
import { products } from './productlistexp';
import no_data from '../images/no_data.png';

function ProductDetail() {
  
  const {state,pathname} = useLocation()     
  const location = useLocation()

  const query = new URLSearchParams(location.search).get('product');

  const [data,setdata] = useState({})

  console.log("state",state)


  useEffect(()=>{
    if(query !== null){
        setdata(products.find((f)=>f?.name.toLowerCase() === query))
    }

  },[query])
  
//   console.log("data",data)

  function openExternalLink(v){
    const url = 'https://apps.apple.com/in/app/wudgres/id6449000418'
    const url1 = 'https://play.google.com/store/apps/details?id=com.wudgresreal'

    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }
  }

//   console.log("amma",state)
    
  return (
    <div className='relative'>
        <Toaster />

        {query === null  ? 
        <>
        {!(state === null) && <div className='md:flex relative items-center mx-6 md:mx-20 my-10'>
            <CopyToClipboard text={`${app_url}${pathname}?product=${state?.name?.toLowerCase()}`} onCopy={()=>toast.success("URL Copied Successfully!")}>
            <span className='group hover:text-[#cbab75] cursor-pointer flex items-center absolute -top-4 right-0 p-1 flex-col md:flex-row bg-black sm:border md:border-none sm:border-[#ddd] md:bg-white'><RiShareForwardFill className='p-1 text-white md:text-black group-hover:text-[#cbab75]' size={30}/>  <span className='text-white block text-[10px] md:text-black group-hover:text-[#cbab75] font-[600]'>Share</span></span>
            </CopyToClipboard>
           <img className={`md:h-[600px] md:w-[30%] object-contain`}  src={state?.img} alt='_img'/>
           <div className=' mt-10 ml-2 md:ml-12 md:-mt-28'>
                <div className='flex my-2 '>
                    <span className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DESCRIPTION </span>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <span className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'> {state?.description}</span>
                </div>
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>SERIES </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'>{state?.series} </h6>
                </div>
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DESIGN </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'>{state?.name?.replace('_',' ')} </h6>
                </div>

                {(state?.veneers !== '' && state?.veneers !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>VEENERS </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.veneers} </h6>
                </div>}

                {(state?.pvc_foil !== '' && state?.pvc_foil !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>PVC FOIL  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.pvc_foil} </h6>
                </div>}

                {(state?.domes !== '' && state?.domes !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DOMES  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.domes} </h6>
                </div>}

                {(state?.primer_color !== '' && state?.primer_color !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] uppercase max-w-[100px]'>Primer Color  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.primer_color} </h6>
                </div>}


                {(state?.laminate !== '' && state?.laminate !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>LAMINATE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.laminate} </h6>
                </div>}

                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>SIZE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                     <div className='ml-0'>
                     {state?.thickness !== '' &&   
                     <h6 className='text-[13px] md:text-[13px]  mb-1 ml-2 text-[#7d7d7d]'>THICKNESS (MM) : {state?.thickness}  </h6>}
                     {state?.height !== '' &&
                     <h6 className='text-[13px] md:text-[13px] mb-1 ml-2 text-[#7d7d7d]'>HEIGHT (INCHES) : {state?.height} </h6>}
                    {state?.width !== '' &&
                     <h6 className='text-[13px] md:text-[13px] mb-1 ml-2 text-[#7d7d7d]'><span>WIDTH (INCHES)</span> : {state?.width} </h6>}
                     <h6 className='text-[12px] md:text-[13px] ml-2 text-[#7d7d7d]'>* Customized size can be made, cost calculated for next standard size </h6>
                    </div>
                </div>
                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>FINISH </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.finish}</h6>
                </div>

                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>USAGE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'>{state?.usage}</h6>
                </div>
                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>TYPE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{state?.type}</h6>
                </div>

                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>WUDGRES APP </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'> To check the price of the door ,download wudgres app</h6>
                </div>

                <div className='mt-5 md:ml-28'>
                <span onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-2 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px]'>Google Play</span>
                <span onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-2 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] ml-2'>App Store</span>
                {/* <CopyToClipboard text={`${app_url}${pathname}`} onCopy={()=>toast.success("URL Copied Successfully!")}>
                <span  className='transition delay-100 duration-200 ease-in border border-[#000] py-0.5 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] ml-2'>Share Product</span>
                </CopyToClipboard> */}
                </div>


           </div>
        </div>}
        </>
        :
        <>
          {!(data === undefined) ? 
          <div className='md:flex relative items-center mx-6 md:mx-20 my-10'>
            <CopyToClipboard text={`${app_url}${pathname}?product=${data?.name?.toLowerCase()}`} onCopy={()=>toast.success("URL Copied Successfully!")}>
            <span className='group hover:text-[#cbab75] cursor-pointer flex items-center absolute -top-4 right-0 p-1 flex-col md:flex-row bg-black sm:border md:border-none sm:border-[#ddd] md:bg-white'><RiShareForwardFill className='p-1 text-white md:text-black group-hover:text-[#cbab75]' size={30}/>  <span className='text-white block text-[10px] md:text-black group-hover:text-[#cbab75] font-[600]'>Share</span></span>
            </CopyToClipboard>
           <img className={`md:h-[600px] md:w-[30%] object-contain  `}  src={data?.img} alt='_img'/>
           <div className=' mt-10 ml-2 md:ml-12 md:-mt-28'>
                <div className='flex my-2 '>
                    <span className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DESCRIPTION </span>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <span className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'> {data?.description}</span>
                </div>
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>SERIES </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'>{data?.series} </h6>
                </div>
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DESIGN </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'>{data?.name?.replace('_',' ')} </h6>
                </div>

                {(data?.veneers !== '' && data?.veneers !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>VEENERS </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{data?.veneers} </h6>
                </div>}

                {(data?.pvc_foil !== '' && data?.pvc_foil !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>PVC FOIL  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{data?.pvc_foil} </h6>
                </div>}

                {(data?.domes !== '' && data?.domes !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>DOMES  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{data?.domes} </h6>
                </div>}

                {(data?.primer_color !== '' && data?.primer_color !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] uppercase max-w-[100px]'>Primer Color  </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{data?.primer_color} </h6>
                </div>}


                {(data?.laminate !== '' && data?.laminate !== undefined) &&
                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>LAMINATE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] uppercase ml-2 text-[#7d7d7d]'>{data?.laminate} </h6>
                </div>}

                <div className='flex my-2'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>SIZE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                     <div className='ml-2'>
                     {state?.thickness !== '' &&   
                     <h6 className='text-[13px] md:text-[13px]  mb-1 ml-2 text-[#7d7d7d]'>THICKNESS (MM) : {data?.thickness}  </h6>}
                     {state?.height !== '' &&
                     <h6 className='text-[13px] md:text-[13px] mb-1 ml-2 text-[#7d7d7d]'>HEIGHT (INCHES) : {data?.height} </h6>}
                    {state?.width !== '' &&
                     <h6 className='text-[13px] md:text-[13px] mb-1 ml-2 text-[#7d7d7d]'><span>WIDTH (INCHES)</span> : {data?.width} </h6>}
                     <h6 className='text-[12px] md:text-[13px] ml-2 text-[#7d7d7d]'>* Customized size can be made, cost calculated for next standard size </h6>
                    </div>
                </div>
                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>FINISH </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'>{data?.finish}</h6>
                </div>

                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>USAGE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 uppercase text-[#7d7d7d]'>{data?.usage}</h6>
                </div>
                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>TYPE </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'>{data?.type}</h6>
                </div>

                <div className='flex my-2 items-center'>
                    <h6 className='text-[13px] font_section1  min-w-[100px] max-w-[100px]'>WUDGRES APP </h6>
                    <span className='font_section1 text-black text-[13px]'>:</span> 
                    <h6 className='text-[13px] md:text-[13px] ml-2 text-[#7d7d7d]'> To check the price of the door ,download wudgres app</h6>
                </div>

                <div className='mt-5 md:ml-28'>
                <span onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-0.5 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px]'>Google Play</span>
                <span onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-0.5 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] ml-2'>App Store</span>
                {/* <CopyToClipboard text={`${app_url}${pathname}`} onCopy={()=>toast.success("URL Copied Successfully!")}>
                <span  className='transition delay-100 duration-200 ease-in border border-[#000] py-0.5 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] ml-2'>Share Product</span>
                </CopyToClipboard> */}
                </div>


           </div>
           
        </div> 
        : 

           <div className='p-5 md:w-[80%] my-10 flex flex-col items-center justify-center mx-auto'>
            <img src={no_data} alt="no_data" className='md:h-[250px]' />
            <h6 className='text-center mb-2 text-[22px] font-[700]'>Oops Something Went Wrong!</h6>
            <h6 className='text-[14px] md:text-[13px] md:w-[50%] text-center'>Sorry We couldn't find the product your looking for don't worry we have recieved your requirment if the product is not there we will update you.</h6>
           </div>
        }

        </>}

    </div>
  )
}

export default ProductDetail