import React, { useState } from 'react'
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';

function About() {

  const navigator = useNavigate()

  const [step,setstep] = useState('')

  return (
    <div className='w-screen overflow-x-hidden'>
     <div className='md:mt-0 grid place-items-center justify-items-center text-white bg-cover h-[230px]   left-0  bg-fixed w-full bg-about md:h-[350px] md:bg-cover bg-no-repeat bg-center'>
      <div>
        <h6 className='text-[30px]  text-center tracking-[10px] font_expand'>ABOUT US</h6>
        <h6 className='text-center tracking-wider text-gray-600 opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / About </h6>
        <h6 className='text-[14px] font_thick text-center opacity-70'>We Make Things With Love Which Are Enviornment Friendly</h6>
      </div>
    </div>



    <div className='relative w-full px-10 sm:px-0 mx-auto md:w-8/12 py-7 my-0'>


      <div className='md:flex my-10'>
            <div data-aos="fade-up" onClick={()=>setstep('Introduction')} className='group bg-[#cbab75] h-[140px]  group cursor-pointer shadow-md  mb-2 md:mb-0 w-full p-2 mx-2 flex items-center justify-center'>
            <h6 className='uppercase text-center transition-all group-hover:scale-125 duration-300 mt-2 text-[30px] text-white font-[700]'>Introduction</h6>
            </div>

            <div data-aos="fade-up" onClick={()=>setstep("Chairman's Message")} className='group bg-[#cbab75] h-[140px]  group cursor-pointer shadow-md mb-2 md:mb-0 w-full p-2 mx-2 flex items-center justify-center'>
            <h6 className='uppercase text-center transition-all group-hover:scale-125  duration-300 mt-2 text-[30px] text-white font-[700]'>Chairman's Message</h6>
            </div>

            <div data-aos="fade-up" onClick={()=>setstep('Wudgres')} className='group bg-[#cbab75] h-[140px]  group cursor-pointer shadow-md mb-2 md:mb-0 w-full p-2 mx-2 flex items-center justify-center'>
            <h6 className='uppercase text-center transition-all group-hover:scale-125 duration-300 mt-2 text-[30px] text-white font-[700]'>Wudgres</h6>
            </div>

      </div>



      {step === "Introduction" &&
      <div className='mx-2'>
         <h6 className='text-[14px]  mb-5 font-[400] text-justify font_thick'>Diwani Group started its first entity in the year 1991 to supply cut-size wood with
          an innate focus on consistently supreme quality, an ethical and transparent
          business process, and a firm commitment to deadlines. This helped the company gain adequate attention and precious faith. Since its inception, the Diwani Group has been steadily growing, widening and diversifying. WudGres was born out of this diversification, and 2 manufacturing units spread over 2,00,000 square feet cater to the demand. With the successful acceptance of products in the market and an expanding customer base, a third unit covering 45000 square feet is planned to meet the ever-increasing demand.</h6>
      </div>} 

      {step === "Wudgres" &&
      <div className='mx-2'>
         <h6 className='text-[13px] mb-5 font-[400]  font_thick'>WudGres re-defines and re-invents Doors, Plywood, Block Board and other timber product solutions in India. The WudGres range of products are the
strongest, finest, and safest, which are committed to being thoroughly resilient, cost-effective, and consistent to serve the requirements in the market place and fulfil the needs of interior building solutions. Combined with the latest technology and machinery from Europe, Taiwan, and India, and also the cutting edge product engineering blended with advanced chemical
composition, it gives an undisputed edge. WudGres is the first mover with new technology, so WudGres has the first mover advantage to offer high definition, techno-packed Ply-Board-Door solutions. WudGres strives hard to find the
right balance between Ecological Harmony and Sustained Growth and is sincerely sensitive towards its contribution to Mother Earth.</h6>
      </div>}   

      {step === "Chairman's Message" &&
      <div className='mx-2 '>
      <div className='flex flex-col sm:flex-row items-start'>
              <img src="https://wudgres.s3.ap-south-1.amazonaws.com/Screenshot+2023-06-04+at+3.23.17+PM.png" className='w-full h-[200px] mt-0 object-contain md:w-[50%]' />
              <div className='font_thick ml-0 mt-10 sm:mt-0 md:ml-10 pt-2'>
          {/* <h6 className='text-[18px] font-[900] font_bold  mb-5'>CHAIRMAN MESSAGE</h6> */}
                  <h6 className='text-[14px] mb-5 font-[400]  thick'>"I bow my head to mother earth, who has provided all her natural resources for the good of all of us. but as her children, we are reckless and exploiting them in a dangerous way. i know we cannot do away with these resources so easily, but we have to find a way to optimize and use them efficiently to keep the waste to a bare minimum."</h6>
                  <h6 className='text-[14px] my-5 font_thick'>With over 32 years of experience gained over the years, Mr Prabhudas Patel, as Chairman of Diwani Group, is spearheading the wood-based industry and has been a guiding force not only to his next generation but also to many.</h6>
                  <img src="https://wudgres.com/wp-content/uploads/2023/01/Prabhudas-Patel.gif" className='w-40' />
              </div>
      </div>


      <div className='relative pt-4'>
        {/* <h6 className='text-center text-[18px] font_bold my-10 mt-16'>ITS ALL ABOUT DIWANI GROUP</h6> */}

        {/* <h6 className='text-[50px] text-center tracking-[20px] font_expand'>ABOUT US</h6> */}
      <h1 data-aos="fade-left" className='font_thick text-[40px] md:text-[70px] absolute right-0 top-4 font_thicker text-[#cbab75] '>DIWANI GROUP</h1>


        <h6 className='text-[14px] font_thick my-5 mt-32 md:mt-10 md:pt-16 font_thick'>At Diwani Group, we have been making products where we make the best from left-over wood from so many Saw Mills which is usually used as firewood. All the products made under WudGres are of international standards and made to last, so there is little or no need to replace them over time, which helps in saving on wood, which is again its replacement.</h6>
        <h6 className='text-[14px] font_thick my-5 font_thick'>We know brands are not made overnight and a lot of hard work goes into bringing them to the top of the table. Even more difficult is to hold onto that position, which is only possible with changing with time, upgrading technology, and consistent innovation, and giving the best products to our customers that they deserve for the hard-earned money spent on buying them.</h6>

        <h6 className='text-[14px] my-5 font_thick'>It is our consistent effort to provide a complete interior solution under WudGres. Keeping this in mind, we have been expanding our existing portfolios and also bringing in new products to make things easier for our customers.</h6>

        <h6 className='text-[14px] my-5 font_thick'>With the effort of my hardworking team, we hope to see a WudGres in every home.</h6>
        

        <div className='grid grid-cols-2 md:grid-cols-4 justify-between border-t border-b my-10 mb-15 p-5'>

            <div className='border-r'>
                <h6 className='text-center font-[800] text-[24px] '><CountUp duration={10} end={3} /></h6>
                <h6 className='font-[400] mt-1 text-[#6e6e6e] font_thick text-center text-[14px]'>BRANDS OWNED BY DIWANI</h6>
            </div>
            <div className='md:border-r'>
                <h6 className='text-center font-[800] text-[24px] '><CountUp duration={10} end={16} /></h6>
                <h6 className='font-[400] mt-1 text-[#6e6e6e] font_thick text-center text-[14px]'>YEAR IN BUISNESS</h6>
            </div>
            <div className='border-r mt-5 md:mt-0'>
                <h6 className='text-center font-[800] text-[24px] '><CountUp duration={10} end={200} /> +</h6>
                <h6 className='font-[400] mt-1 text-[#6e6e6e] font_thick text-center text-[14px]'>NUMBER OF PRODUCTS</h6>
            </div>
            <div className='md:border-r mt-5 md:mt-0 md:border-none'>
                <h6 className='text-center font-[800] text-[24px] '><CountUp duration={10} end={1298} /> +</h6>
                <h6 className='font-[400] mt-1 text-[#6e6e6e] font_thick text-center text-[14px]'>SATISFIED CUSTOMERS</h6>
            </div>
        </div>

      </div>

     

      <div className='relative'>
        {/* <h6 className='text-center text-[18px] font_bold my-10 mt-16'>WHY WUDGRES</h6> */}

        {/* <h6 className='text-[50px] text-center tracking-[20px] font_expand'>ABOUT US</h6> */}
      <h1 data-aos="fade-left" className='text-[40px] md:text-[70px] absolute right-0 -top-6 md:-top-10 font_thicker text-[#cbab75] uppercase'>PEOPLE belive in  US</h1>

      <h6 className='text-[14px] pt-24 md:pt-16  my-5 font_thick'>There's a reason why a Welcome Mat is placed at the foot of door. A door is the warm greeting as you enter the home. Conversely it is the welcoming promise of the world beyond as you leave those walls behind.The closing of a door can bring comfort and security. The opening lead to possibilities and wonder.</h6>
      
      <div className='grid grid-cols-1 gap-1'>

         <div>
           <h6 className="text-[15px] font_bold">High Quality Bonding</h6>
           <h6 className='text-[14px] mt-2 mb-8 font_thick'>WudGres Doors is manufactured with high density hardwood Banyan Wood inside. Two layers of medium density Poplar species at top/bottom of the panel along with Makai face veneer is laminated giving the panel a unparallel strength and stability. A high quality Phenol formaldehyde resin is used in manufacturing WudGres BWR Door & BWP Plywood leading to excellent water proof qualities.</h6>
         </div>

         <div>
           <h6 className="text-[15px] font_bold">Preservative Treatment ,Borer and Termite Resistant, Lab Tested</h6>
           <h6 className='text-[14px] mt-2 mb-8 font_thick'>Glue line Protection and Preservative Treatment make WudGres Doors and Plywood Borers & Termite Resistant. Inhouse Quality checks like Hammer check after hot press to ensure the accurate intensity, effectiveness and press-ability. Thickness Check to ensure no disparity in Chunkiness Lab Tested from time to time to provide defect free and healthy Products every time</h6>
         </div>

         <div>
           <h6 className="text-[15px] font_bold">Seasoned Wood & All Weather Resitant</h6>
           <h6 className='text-[14px] mt-2 mb-8 font_thick'>The Seasoning of Wood as per the International Standards and use of Modern Technology in Manufacturing Process makes the WudGres Decorative Doors, Plywood,Block Board, Resistant to all Weather Conditions.</h6>
         </div>

         <div>
           <h6 className="text-[15px] font_bold">Nature Loving</h6>
           <h6 className='text-[14px] mt-2 mb-8 font_thick'>Being made from timber it decomposes easily when buried without damaging Mother Earth.</h6>
         </div>

      </div>

     </div>
      </div>}

       
    </div>
    </div>
  )
}

export default About