import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'



import timbor_acacia_banner from '../images/doors_banner/timbor_acacia_banner.png'

import ven_decor_legend_banner from '../images/collection_banner/ven_decor_legend_banner.png'
import ven_decor_lavish_banner from '../images/collection_banner/ven_decor_lavish_banner.png'
import ven_decor_elite_banner from '../images/collection_banner/ven_decor_elite_banner.png'
import ven_decor_rich_banner from '../images/doors_banner/ven_decor_rich.png'


import teak_veener_banner from '../images/collection_banner/teek_veener_banner.png'

import lamourous_elite_banner from '../images/collection_banner/lamorous_elite_banner.png'
import lamourous_rich_banner from '../images/collection_banner/lamorous_rich_banner.png'
import lamourous_prime_banner from '../images/collection_banner/lamorous_prime_banner.png'
import lamourous_eco_banner from '../images/collection_banner/lamorous_eco_banner.png'

import metalem_banner from '../images/doors_banner/metalem.png'

import espial_banner from '../images/doors_banner/espial.png'

import divine_banner from '../images/doors_banner/divine.png'

import embozz_banner from '../images/doors_banner/embozz.png'

import flush_doors_banner from '../images/doors_banner/flush_door.png'

import lamina_rich_banner from '../images/doors_banner/lamina_rich.png'
import lamina_eco_banner from '../images/doors_banner/lamina_eco.png'

import solid_white_rich_banner from '../images/doors_banner/solid_white_rich.png'
import solid_white_eco_banner from '../images/doors_banner/solid_white_eco.png'

// timber acia items 
import wg_1001 from '../images/collection/doors/timber_acia_collection/wg_1001.png';
import wg_1002 from '../images/collection/doors/timber_acia_collection/wg_1002.png';
import wg_1005 from '../images/collection/doors/timber_acia_collection/wg_1005.png';
import wg_1006 from '../images/collection/doors/timber_acia_collection/wg_1006.png';
import wg_1007 from '../images/collection/doors/timber_acia_collection/wg_1007.png';
import wg_1009 from '../images/collection/doors/timber_acia_collection/wg_1009.png';
import wg_1014 from '../images/collection/doors/timber_acia_collection/wg_1014.png';
import wg_2dhk from '../images/collection/doors/timber_acia_collection/wg_2dhk.png';
import wg_4dhk from '../images/collection/doors/timber_acia_collection/wg_4dhk.png';
import wg_1051 from '../images/collection/doors/timber_acia_collection/wg_1051.png';
import wg_1052 from '../images/collection/doors/timber_acia_collection/wg_1052.png';
import wg_1054 from '../images/collection/doors/timber_acia_collection/wg_1054.png';
import wg_1055 from '../images/collection/doors/timber_acia_collection/wg_1055.png';
import wg_1057 from '../images/collection/doors/timber_acia_collection/wg_1057.png';
import wg_1058 from '../images/collection/doors/timber_acia_collection/wg_1058.png';

// ven decor legend items 
import wg_5001 from '../images/collection/doors/ven_decor_legend/wg_5001.png';
import wg_5002 from '../images/collection/doors/ven_decor_legend/wg_5002.png';
import wg_5003 from '../images/collection/doors/ven_decor_legend/wg_5003.png';
import wg_5004 from '../images/collection/doors/ven_decor_legend/wg_5004.png';
import wg_5005 from '../images/collection/doors/ven_decor_legend/wg_5005.png';
import wg_5006 from '../images/collection/doors/ven_decor_legend/wg_5006.png';

// ven decor lavish items 
import wg_3001 from '../images/collection/doors/ven_decor_lavish/wg_3001.png';
import wg_3002 from '../images/collection/doors/ven_decor_lavish/wg_3002.png';
import wg_3003 from '../images/collection/doors/ven_decor_lavish/wg_3003.png';
import wg_3005 from '../images/collection/doors/ven_decor_lavish/wg_3005.png';
import wg_3006 from '../images/collection/doors/ven_decor_lavish/wg_3006.png';
import wg_3007 from '../images/collection/doors/ven_decor_lavish/wg_3007.png';
import wg_3008 from '../images/collection/doors/ven_decor_lavish/wg_3008.png';
import wg_3009 from '../images/collection/doors/ven_decor_lavish/wg_3009.png';
import wg_3010 from '../images/collection/doors/ven_decor_lavish/wg_3010.png';
import wg_3011 from '../images/collection/doors/ven_decor_lavish/wg_3011.png';
import wg_3012 from '../images/collection/doors/ven_decor_lavish/wg_3012.png';
import wg_3013 from '../images/collection/doors/ven_decor_lavish/wg_3013.png';

// ven decor elite items 
import wg_5501 from '../images/collection/doors/ven_decor_elite/wg_5501.png';
import wg_5502 from '../images/collection/doors/ven_decor_elite/wg_5502.png';
import wg_5503 from '../images/collection/doors/ven_decor_elite/wg_5503.png';
import wg_5504 from '../images/collection/doors/ven_decor_elite/wg_5504.png';
import wg_5505 from '../images/collection/doors/ven_decor_elite/wg_5505.png';
import wg_5506 from '../images/collection/doors/ven_decor_elite/wg_5506.png';

// ven decor rich items 
import wg_902 from '../images/collection/doors/ven_decor_rich/wg_902.png';
import wg_903 from '../images/collection/doors/ven_decor_rich/wg_903.png';
import wg_906 from '../images/collection/doors/ven_decor_rich/wg_906.png';
import wg_907 from '../images/collection/doors/ven_decor_rich/wg_907.png';
import wg_908 from '../images/collection/doors/ven_decor_rich/wg_908.png';
import wg_909 from '../images/collection/doors/ven_decor_rich/wg_909.png';
import wg_910 from '../images/collection/doors/ven_decor_rich/wg_910.png';
import wg_911 from '../images/collection/doors/ven_decor_rich/wg_911.png';
import wg_913 from '../images/collection/doors/ven_decor_rich/wg_913.png';
import wg_914 from '../images/collection/doors/ven_decor_rich/wg_914.png';
import wg_915 from '../images/collection/doors/ven_decor_rich/wg_915.png';
import wg_916D from '../images/collection/doors/ven_decor_rich/wg_916D.png';
import wg_917D from '../images/collection/doors/ven_decor_rich/wg_917D.png';
import wg_919 from '../images/collection/doors/ven_decor_rich/wg_919.png';
import wg_920 from '../images/collection/doors/ven_decor_rich/wg_920.png';

// teak veener items
import wg_501 from '../images/collection/doors/teak_venner/wg_501.png';
import wg_503 from '../images/collection/doors/teak_venner/wg_503.png';
import wg_505_horizontal from '../images/collection/doors/teak_venner/wg_505_horizontal.png';
import wg_505_vertical from '../images/collection/doors/teak_venner/wg_505_vertical.png';
import wg_506_horizontal from '../images/collection/doors/teak_venner/wg_506_horizontal.png';
import wg_506 from '../images/collection/doors/teak_venner/wg_506.png';


// lamorous elite collection 
import wg_4001 from '../images/collection/doors/lamorous_elite/wg_4001.png';
import wg_4002 from '../images/collection/doors/lamorous_elite/wg_4002.png';
import wg_4003 from '../images/collection/doors/lamorous_elite/wg_4003.png';
import wg_4004 from '../images/collection/doors/lamorous_elite/wg_4004.png';
import wg_4005 from '../images/collection/doors/lamorous_elite/wg_4005.png';
import wg_4006 from '../images/collection/doors/lamorous_elite/wg_4006.png';

// lamorous rich collection 
import wg_826 from '../images/collection/doors/lamorous_rich/WG_826.png';
import wg_827 from '../images/collection/doors/lamorous_rich/WG_827.png';
import wg_828 from '../images/collection/doors/lamorous_rich/WG_828.png';
import wg_829 from '../images/collection/doors/lamorous_rich/WG_829.png';
import wg_830 from '../images/collection/doors/lamorous_rich/WG_830.png';
import wg_831 from '../images/collection/doors/lamorous_rich/WG_831.png';
import wg_832 from '../images/collection/doors/lamorous_rich/WG_832.png';
import wg_833 from '../images/collection/doors/lamorous_rich/WG_833.png';
import wg_834 from '../images/collection/doors/lamorous_rich/WG_834.png';
import wg_835 from '../images/collection/doors/lamorous_rich/WG_835.png';
import wg_836 from '../images/collection/doors/lamorous_rich/WG_836.png';
import wg_837 from '../images/collection/doors/lamorous_rich/WG_837.png';
import wg_838 from '../images/collection/doors/lamorous_rich/WG_838.png';
import wg_839 from '../images/collection/doors/lamorous_rich/WG_839.png';
import wg_840 from '../images/collection/doors/lamorous_rich/WG_840.png';
import wg_841 from '../images/collection/doors/lamorous_rich/WG_841.png';
import wg_842 from '../images/collection/doors/lamorous_rich/WG_842.png';
import wg_843 from '../images/collection/doors/lamorous_rich/WG_843.png';
import wg_844 from '../images/collection/doors/lamorous_rich/WG_844.png';
import wg_846 from '../images/collection/doors/lamorous_rich/WG_846.png';
import wg_847 from '../images/collection/doors/lamorous_rich/WG_847.png';

// lamorous prime collection 
import wg_4501 from '../images/collection/doors/lamorous_prime/wg_4501.png';
import wg_4503 from '../images/collection/doors/lamorous_prime/wg_4503.png';
import wg_4504 from '../images/collection/doors/lamorous_prime/wg_4504.png';
import wg_4505 from '../images/collection/doors/lamorous_prime/wg_4505.png';
import wg_4506 from '../images/collection/doors/lamorous_prime/wg_4506.png';


// lamorous eco collection 
import wg_851 from '../images/collection/doors/lamorous_eco/wg_851.png';
import wg_852 from '../images/collection/doors/lamorous_eco/wg_852.png';
import wg_854 from '../images/collection/doors/lamorous_eco/wg_854.png';
import wg_855 from '../images/collection/doors/lamorous_eco/wg_855.png';
import wg_859 from '../images/collection/doors/lamorous_eco/wg_859.png';
import wg_862 from '../images/collection/doors/lamorous_eco/wg_862.png';
import wg_864 from '../images/collection/doors/lamorous_eco/wg_864.png';
import wg_865 from '../images/collection/doors/lamorous_eco/wg_865.png';
import wg_866 from '../images/collection/doors/lamorous_eco/wg_866.png';
import wg_867 from '../images/collection/doors/lamorous_eco/wg_867.png';
import wg_869 from '../images/collection/doors/lamorous_eco/wg_869.png';
import wg_870 from '../images/collection/doors/lamorous_eco/wg_870.png';
import wg_871 from '../images/collection/doors/lamorous_eco/wg_871.png';
import wg_873 from '../images/collection/doors/lamorous_eco/wg_873.png';
import wg_874 from '../images/collection/doors/lamorous_eco/wg_874.png';
import wg_875 from '../images/collection/doors/lamorous_eco/wg_875.png';
import wg_876 from '../images/collection/doors/lamorous_eco/wg_876.png';
import wg_877 from '../images/collection/doors/lamorous_eco/wg_877.png';
import wg_878 from '../images/collection/doors/lamorous_eco/wg_878.png';


// metalem collection 
import wg_401 from '../images/collection/doors/metalem/wg_401.png';
import wg_403 from '../images/collection/doors/metalem/wg_403.png';
import wg_404 from '../images/collection/doors/metalem/wg_404.png';

// espial collection 
import wg_301 from '../images/collection/doors/espial/wg_301.png';
import wg_303 from '../images/collection/doors/espial/wg_303.png';
import wg_307 from '../images/collection/doors/espial/wg_307.png';

// devine collection 
import wg_101 from '../images/collection/doors/divine/wg_101.png';
import wg_102 from '../images/collection/doors/divine/wg_102.png';
import wg_103 from '../images/collection/doors/divine/wg_103.png';

// embozz collection 
import wg_223 from '../images/collection/doors/embozz/wg_223.png';
import wg_224 from '../images/collection/doors/embozz/wg_224.png';
import wg_227 from '../images/collection/doors/embozz/wg_227.png';

// lamina rich collection 
import wg_033D from '../images/collection/doors/lamina_rich/wg_033D.png';
import wg_034D from '../images/collection/doors/lamina_rich/wg_034D.png';
import wg_035D from '../images/collection/doors/lamina_rich/wg_035D.png';

// lamina eco collection 
import wg_001 from '../images/collection/doors/lamina_eco/wg_001.png';
import wg_003 from '../images/collection/doors/lamina_eco/wg_003.png';
import wg_006 from '../images/collection/doors/lamina_eco/wg_006.png';
import wg_007 from '../images/collection/doors/lamina_eco/wg_007.png';
import wg_008 from '../images/collection/doors/lamina_eco/wg_008.png';
import wg_013 from '../images/collection/doors/lamina_eco/wg_013.png';
import wg_016 from '../images/collection/doors/lamina_eco/wg_016.png';
import wg_017 from '../images/collection/doors/lamina_eco/wg_017.png';
import wg_020 from '../images/collection/doors/lamina_eco/wg_020.png';

// solid white rich collection 
import wg_1701 from '../images/collection/doors/solid_white_rich/wg_1701.png';
import wg_1702 from '../images/collection/doors/solid_white_rich/wg_1702.png';
import wg_1703 from '../images/collection/doors/solid_white_rich/wg_1703.png';
import wg_1704 from '../images/collection/doors/solid_white_rich/wg_1704.png';
import wg_1705 from '../images/collection/doors/solid_white_rich/wg_1705.png';
import wg_1706 from '../images/collection/doors/solid_white_rich/wg_1706.png';
import wg_1708 from '../images/collection/doors/solid_white_rich/wg_1708.png';
import wg_1710 from '../images/collection/doors/solid_white_rich/wg_1710.png';
import wg_1711 from '../images/collection/doors/solid_white_rich/wg_1711.png';

// solid white eco collection 
import wg_751 from '../images/collection/doors/solid_white_eco/wg_751.png';
import wg_752 from '../images/collection/doors/solid_white_eco/wg_752.png';
import wg_753 from '../images/collection/doors/solid_white_eco/wg_753.png';
import wg_754 from '../images/collection/doors/solid_white_eco/wg_754.png';
import wg_756 from '../images/collection/doors/solid_white_eco/wg_756.png';
import wg_760 from '../images/collection/doors/solid_white_eco/wg_760.png';
import wg_761 from '../images/collection/doors/solid_white_eco/wg_761.png';
import wg_762 from '../images/collection/doors/solid_white_eco/wg_762.png';
import wg_763 from '../images/collection/doors/solid_white_eco/wg_763.png';


import wpc1 from '../images/wpc1.png'
import wpc2 from '../../src/images/wpc2.png'


import woodframe1 from '../images/wood_frame1.png'
import woodframe2 from '../images/wood_frame2.png'


import bwr1 from '../images/bwr1.jpg'
import bwp1 from '../images/bwp1.jpeg'

function Products() {

  const [step,setstep] = useState(1)  

  const {state,pathname} = useLocation()
  const navigator = useNavigate()

  let path = pathname?.split('/')[2]
  let path1 = pathname?.split('/')[3]

  useEffect(()=>{
    if(path === 'doors'){
      setstep(1)
    }

    if(path === 'wpc'){
      setstep(2)
    }

    if(path === 'window_shutters'){
      setstep(3)
    }

    if(path === 'plywood'){
      setstep(4)
    }

    if(path === 'wood_frames'){
      setstep(5)
    }

  },[pathname])

  function openExternalLink(v){
    const url = 'https://apps.apple.com/in/app/wudgres/id6449000418'
    const url1 = 'https://play.google.com/store/apps/details?id=com.wudgresreal'

    if(v === 1){
        window.open(url, '_blank');
    }else if(v === 2){
        window.open(url1, '_blank');
    }
  }


  // VENDECOR 

  const vendecor_legend = [
    {
      img:wg_5001,
      name:'WG_5001',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Legend',
      description:'Nature has its own way of reflecting beauty reflected in the wild and yet feels comfortable.WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',

    },
    {
      img:wg_5002,
      name:'WG_5002',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Legend',
      description:'Nature has its own way of reflecting beauty reflected in the wild and yet feels comfortable.WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',

    },
    {
      img:wg_5003,
      name:'WG_5003',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Legend',
      description:'Nature has its own way of reflecting beauty reflected in the wild and yet feels comfortable.WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',

    },
    {
      img:wg_5004,
      name:'WG_5004',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC GRANDILO , ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Legend',
      description:'Nature has its own way of reflecting beauty reflected in the wild and yet feels comfortable.WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',

    },
    {
      img:wg_5005,
      name:'WG_5005',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC GRANDILO , ROYALE FRSH JIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Legend',
      description:'Nature has its own way of reflecting beauty reflected in the wild and yet feels comfortable.WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',

    },
    {
      img:wg_5006,
      name:'WG_5006',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC GRANDILO , ROYALE FRESH JIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Legend',
      description:'Nature has its own way of reflecting beauty reflected in the wild and yet feels comfortable.WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',

    },
  ]

  const vendecor_lavish = [
    {
      img:wg_3001,
      name:'WG_3001',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , METALIC GRANDILO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3002,
      name:'WG_3002',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALIC GRANDILO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3003,
      name:'WG_3003',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3005,
      name:'WG_3005',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'ROYALE RIO ROSEWOOD , METALLIC GRANDILO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3006,
      name:'WG_3006',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3007,
      name:'wg_3007',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'ROYALE FRESJIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3008,
      name:'wg_3008',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'ROYALE FRESJIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3009,
      name:'wg_3009',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3010,
      name:'wg_3010',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE FRESJIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3011,
      name:'wg_3011',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE FRESJIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3012,
      name:'wg_3012',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE FRESJIO',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    },
    {
      img:wg_3013,
      name:'wg_3013',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Metallic Veneer Door, Exotic Veneer Door',
      veneers:'METALLIC SUGAR MAPLE , ROYALE RIO ROSEWOOD',
      usage:'Interior & Exterior',
      series:'Ven Decor Lavish',
      description:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",

    }

  ]

  const vendecor_elite = [
    {
      img:wg_5501,
      name:'WG_5501',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Elite',
      description:"Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.",

    },
    {
      img:wg_5502,
      name:'wg_5502',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Elite',
      description:"Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.",

    },
    {
      img:wg_5503,
      name:'WG_5503',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Elite',
      description:"Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.",

    },
    {
      img:wg_5504,
      name:'WG_5504',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Elite',
      description:"Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.",

    },
    {
      img:wg_5505,
      name:'WG_5505',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Elite',
      description:"Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.",

    },
    {
      img:wg_5506,
      name:'WG_5506',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Elite',
      description:"Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.",

    }

  ]

  const vendecor_rich = [
    {
      img:wg_902,
      name:'WG_902',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'BRAZILIAN LIMBA , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",
    },
    {
      img:wg_903,
      name:'WG_903',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_906,
      name:'WG_906',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_907,
      name:'WG_907',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_908,
      name:'WG_908',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_909,
      name:'WG_909',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_910,
      name:'WG_910',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'BRAZILIAN LIMBA , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_911,
      name:'WG_911',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_913,
      name:'WG_913',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_914,
      name:'WG_914',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_915,
      name:'WG_915',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL , DARK FANTASY',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_916D,
      name:'WG_916D',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_917D,
      name:'WG_917D',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_919,
      name:'WG_919',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    },
    {
      img:wg_920,
      name:'WG_920',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'Veneer Door, Teak Veneer Door, Burma Teak Veneer Door, Smoked Veneer Door',
      veneers:'KING TEAK CROWN VERTICAL',
      usage:'Interior & Exterior',
      series:'Ven Decor Rich',
      description:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",

    }
  ]


  //DIVINE 
  const devine = [
    {
      img:wg_101,
      name:'WG_101',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'NATURAL TEAK',
      usage:'Interior',
      series:'Divine',
      description:"Bring divinity to your home with WudGres Divine Series doors. They are always ready to greet you at your divine place with ancient and contemporary designs that have a soft and smooth finish to give you a true divine feeling.",
    },
    {
      img:wg_102,
      name:'WG_102',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ROSE WOOD',
      usage:'Interior',
      series:'Divine',
      description:"Bring divinity to your home with WudGres Divine Series doors. They are always ready to greet you at your divine place with ancient and contemporary designs that have a soft and smooth finish to give you a true divine feeling.",
    },
    {
      img:wg_103,
      name:'WG_103',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ANDHRA TEAK',
      usage:'Interior',
      series:'Divine',
      description:"Bring divinity to your home with WudGres Divine Series doors. They are always ready to greet you at your divine place with ancient and contemporary designs that have a soft and smooth finish to give you a true divine feeling.",
    },
    

  ]
   
  // embozz 
  const embozz = [
    {
      img:wg_223,
      name:'WG_223',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      laminate:'MAHOGONY',
      usage:'Interior',
      series:'Embozz',
      description:"Inspired by Egyptian, Floral and Modern Art, The WudGres Embozz series is flawless in its design and finish. This is why people consider each door to be a masterpiece. A masterpiece that gives them a better life. Rule the world of decor through this piece of art at work for you.",
    },
    {
      img:wg_224,
      name:'WG_224',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      laminate:'NATURAL WENGE',
      usage:'Interior',
      series:'Embozz',
      description:"Inspired by Egyptian, Floral and Modern Art, The WudGres Embozz series is flawless in its design and finish. This is why people consider each door to be a masterpiece. A masterpiece that gives them a better life. Rule the world of decor through this piece of art at work for you.",
    },
    {
      img:wg_227,
      name:'WG_227',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      laminate:'ROSE WOOD',
      usage:'Interior',
      series:'Embozz',
      description:"Inspired by Egyptian, Floral and Modern Art, The WudGres Embozz series is flawless in its design and finish. This is why people consider each door to be a masterpiece. A masterpiece that gives them a better life. Rule the world of decor through this piece of art at work for you.",
    },
    

  ]
  
  // espial 
  const espial = [
    {
      img:wg_301,
      name:'WG_301',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'NATURAL TEAK',
      usage:'Interior',
      series:'Espial',
      description:"The very taste of elegance and fashion has become more defining. The reflective styles of this collection are very seductive and enchanting, making everyone fall for them. Luxury is an ease and a comfort to explore in order to find solaceasacompanion.",
    },
    {
      img:wg_303,
      name:'WG_303',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ROSE WOOD',
      usage:'Interior',
      series:'Espial',
      description:"The very taste of elegance and fashion has become more defining. The reflective styles of this collection are very seductive and enchanting, making everyone fall for them. Luxury is an ease and a comfort to explore in order to find solaceasacompanion.",
    },
    {
      img:wg_307,
      name:'WG_307',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ANDHRA TEAK',
      usage:'Interior',
      series:'Espial',
      description:"The very taste of elegance and fashion has become more defining. The reflective styles of this collection are very seductive and enchanting, making everyone fall for them. Luxury is an ease and a comfort to explore in order to find solaceasacompanion.",
    },
    

  ]

  // lamina eco
  const lamina_eco = [
    {
      img:wg_001,
      name:'WG_001',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ANDHRA TEAK',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_003,
      name:'WG_003',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'MAHAGONY',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_006,
      name:'WG_006',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'JUNGLE TEAK',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_007,
      name:'WG_007',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'NATURAL WENGE',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_008,
      name:'WG_008',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'NATURAL TEAK',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_013,
      name:'WG_013',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ROSE WOOD',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_016,
      name:'WG_016',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'JUNGLE TEAK',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_017,
      name:'WG_017',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'NATURAL WENGE',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    },
    {
      img:wg_020,
      name:'WG_020',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ANDHRA TEAK',
      usage:'Interior',
      series:'Lamina Eco',
      description:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
    }
  ]
   
  // lamina rich
  const lamina_rich = [
    {
      img:wg_033D,
      name:'WG_033D',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ROSE WOOD',
      domes:'BLOOM 85MM , TOMB 65MM',
      usage:'Interior',
      series:'Lamina Rich',
      description:"Brass ornaments have always been adding beauty to the interiors since older civilization and the same is the case with the Lamina Rich collection of WudGres. The fusion of brass articles on foux wood grains leaves an everlasting impression and this combination never ages with time.",
    },
    {
      img:wg_034D,
      name:'WG_034D',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'JUNGLE TEAK',
      domes:'KELLY 65MM , TOMB 100MM',
      usage:'Interior',
      series:'Lamina Rich',
      description:"Brass ornaments have always been adding beauty to the interiors since older civilization and the same is the case with the Lamina Rich collection of WudGres. The fusion of brass articles on foux wood grains leaves an everlasting impression and this combination never ages with time.",
    },
    {
      img:wg_035D,
      name:'WG_035D',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'MAHAGONY',
      domes:'TOMB 65MM , TOMB 100MM',
      usage:'Interior',
      series:'Lamina Rich',
      description:"Brass ornaments have always been adding beauty to the interiors since older civilization and the same is the case with the Lamina Rich collection of WudGres. The fusion of brass articles on foux wood grains leaves an everlasting impression and this combination never ages with time.",
    },
  ]

  // metalem
  const metalem = [
    {
      img:wg_401,
      name:'WG_401',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ROSE WOOD',
      usage:'Interior',
      series:'Metalem',
      description:"Doors play a very significant role ni modern living. WudGres' Metalem series will undoubtedly satisfy the desire to install a luxury door that is well-built and has added strength. Magnificent in Poise and majestic with this minimalistic design,a fresh contemporary take on classic designs.",
    },
    {
      img:wg_403,
      name:'WG_403',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'MAHAGONY',
      usage:'Interior',
      series:'Metalem',
      description:"Doors play a very significant role ni modern living. WudGres' Metalem series will undoubtedly satisfy the desire to install a luxury door that is well-built and has added strength. Magnificent in Poise and majestic with this minimalistic design,a fresh contemporary take on classic designs.",
    },
    {
      img:wg_404,
      name:'WG_404',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Pvc Foil Doors, Membrane Doors, Wood color PVC Membrane Doors',
      pvc_foil:'ANDHRA TEAK',
      usage:'Interior',
      series:'Metalem',
      description:"Doors play a very significant role ni modern living. WudGres' Metalem series will undoubtedly satisfy the desire to install a luxury door that is well-built and has added strength. Magnificent in Poise and majestic with this minimalistic design,a fresh contemporary take on classic designs.",
    },
    

  ]
   
  //  timber_acia 
  const timber_acia = [
    {
      img:wg_1001,
      name:'WG_1001',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1002,
      name:'WG_1002',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1005,
      name:'WG_1005',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1006,
      name:'WG_1006',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1007,
      name:'WG_1007',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1009,
      name:'WG_1009',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',
    },
    {
      img:wg_1014,
      name:'WG_1014',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_2dhk,
      name:'WG_2DHK',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_4dhk,
      name:'WG_4DHK',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1051,
      name:'WG_1051',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1052,
      name:'WG_1052',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1054,
      name:'WG_1054',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1055,
      name:'WG_1055',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1057,
      name:'WG_1057',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    {
      img:wg_1058,
      name:'WG_1058',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'RAW',
      type:'WOODEN DOOR, TIMBER DOOR',
      veneers:'',
      series:'Timbor Acacia',
      usage:'Interior & Exterior',
      description:'Door of this collection are made from the Solid Acacia Wood. This being a high density wood with beautiful grains it makes it perfectly suitable for Main Door.  The Wood is Kiln seasoned to bring the moisture from around 38-42% to around 12-15%',

    },
    
    
    
    

  ]

  //  teak_vener 
  const teak_vener = [
      {
        img:wg_501,
        name:'WG_501',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        type:'TEAK VEENER',
        veneers:'TEEK VEENER QC',
        usage:'Interior & Exterior',
        series:'Teak Veener',
        description:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
      },
      {
        img:wg_503,
        name:'WG_503',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        type:'TEAK VEENER',
        veneers:'TEEK VEENER QC',
        usage:'Interior & Exterior',
        series:'Teak Veener',
        description:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
      },
      {
        img:wg_505_horizontal,
        name:'WG_505_horizontal',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        type:'TEAK VEENER',
        veneers:'TEEK VEENER QC',
        usage:'Interior & Exterior',
        series:'Teak Veener',
        description:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
      },
      {
        img:wg_505_vertical,
        name:'WG_505_vertical',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        type:'TEAK VEENER',
        veneers:'TEEK VEENER QC',
        usage:'Interior & Exterior',
        series:'Teak Veener',
        description:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
      },
      {
        img:wg_506,
        name:'WG_506',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        type:'TEAK VEENER',
        veneers:'TEEK VEENER QC',
        usage:'Interior & Exterior',
        series:'Teak Veener',
        description:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
      },
      {
        img:wg_506_horizontal,
        name:'WG_506_horizontal',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        type:'TEAK VEENER',
        veneers:'TEEK VEENER QC',
        usage:'Interior & Exterior',
        series:'Teak Veener',
        description:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
      },
  ]

  // lamours eco
  const lamours_eco = [
    {
      img:wg_851,
      name:'WG_851',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY TEAK , KORO ROSEWOOD',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_852,
      name:'WG_852',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY TEAK , KORO ROSEWOOD',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_854,
      name:'WG_854',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'REAPER GREY , REAPER PEARL',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_855,
      name:'WG_855',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'REAPER BROWN , REAPER PEARL',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_859,
      name:'WG_859',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'REAPER GREY , REAPER PEARL',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_862,
      name:'WG_862',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'ROSE GRANDIZ HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_864,
      name:'WG_864',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY TEAK , KORO ROSEWOOD',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_865,
      name:'WG_865',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY TEAK , KORO ROSEWOOD',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_866,
      name:'WG_866',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'ROSE GRANDIZ HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_867,
      name:'WG_867',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'TEAK GRANDIS HZ',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_869,
      name:'WG_869',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'BROWN WALNUT',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_870,
      name:'WG_870',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'ROSE GRANDIS HZ',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_871,
      name:'WG_871',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'TEAK GRANDIS HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_873,
      name:'WG_873',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'NATURAL BURMA TEAK',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_874,
      name:'WG_874',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'NATURAL BURMA TEAK',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_875,
      name:'WG_875',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'ROSE GRANDIS TEAK',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_876,
      name:'WG_876',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY WOOD',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_877,
      name:'WG_877',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY WOOD',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
    {
      img:wg_878,
      name:'WG_878',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'VINTAGE GRANO HZ',
      usage:'Interior',
      series:'Lamorous ECO',
      description:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
    },
     
    

  ]

  // lamours elite
  const lamours_elite = [
    {
      img:wg_4001,
      name:'WG_4001',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EUCALYPTUS IVORY , EUCALYPTUS SAND',
      usage:'Interior',
      series:'Lamorous Elite',
      description:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
    },
    {
      img:wg_4002,
      name:'wg_4002',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'SPECTORA , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Elite',
      description:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
    },
    {
      img:wg_4003,
      name:'wg_4003',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'PRESTINE , EUCALYPTUS COAL',
      usage:'Interior',
      series:'Lamorous Elite',
      description:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
    },
    {
      img:wg_4004,
      name:'wg_4004',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'SPECTORA , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Elite',
      description:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
    },
    {
      img:wg_4005,
      name:'wg_4005',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'PRESTINE , EUCALYPTUS COAL',
      usage:'Interior',
      series:'Lamorous Elite',
      description:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
    },
    {
      img:wg_4006,
      name:'wg_4006',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'MARMOR , SLA TO GREY',
      usage:'Interior',
      series:'Lamorous Elite',
      description:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
    },
    
  ]

   // lamours prime
  const lamours_prime = [
    {
      img:wg_4501,
      name:'WG_4501',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'VINTAGE GRANO HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous Prime',
      description:'Display your harmony with the comfort zone inside your space with sophistication and grace, designed to energise the outlandish lifestyle. These vibrant hues will enchant you and speak the language that translates the future version of beauty into reality.',
    },
    {
      img:wg_4503,
      name:'WG_4503',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'TEAK GRANDIZ HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous Prime',
      description:'Display your harmony with the comfort zone inside your space with sophistication and grace, designed to energise the outlandish lifestyle. These vibrant hues will enchant you and speak the language that translates the future version of beauty into reality.',
    },
    {
      img:wg_4504,
      name:'WG_4504',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'VINTAGE GRANO HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous Prime',
      description:'Display your harmony with the comfort zone inside your space with sophistication and grace, designed to energise the outlandish lifestyle. These vibrant hues will enchant you and speak the language that translates the future version of beauty into reality.',
    },
    {
      img:wg_4505,
      name:'WG_4505',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'VINTAGE GRANO HZ , SAW CUT WHITE',
      usage:'Interior',
      series:'Lamorous Prime',
      description:'Display your harmony with the comfort zone inside your space with sophistication and grace, designed to energise the outlandish lifestyle. These vibrant hues will enchant you and speak the language that translates the future version of beauty into reality.',
    },
    {
      img:wg_4506,
      name:'WG_4506',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'READY TO FIT',
      type:'Laminate Door, SF Laminate Door, Wood color Laminate door',
      laminate:'CHERRY TEAK , KORO ROSEWOOD',
      usage:'Interior',
      series:'Lamorous Prime',
      description:'Display your harmony with the comfort zone inside your space with sophistication and grace, designed to energise the outlandish lifestyle. These vibrant hues will enchant you and speak the language that translates the future version of beauty into reality.',
    },
   
  ]

  // lamours rich
  const lamours_rich = [
    {
      img:wg_826,
      name:'WG_826',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EUCALYPTUS IVORY , EUCALYPTUS SAND ',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",
    },
    {
      img:wg_827,
      name:'wg_827',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'SPECTORA , SLATO GREY ',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_828,
      name:'WG_828',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'GOLDEN TEAK ',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_829,
      name:'WG_829',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'MARMOR , SLATO GREY',
      usage:'Interior & Exterior',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_830,
      name:'WG_830',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EUCALYPTUS IVORY , EUCALYPTUS SAND ',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_831,
      name:'WG_831',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'PRESTINE , EUCALYPTUS COAL',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_832,
      name:'WG_832',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'WALNUT BURST',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_833,
      name:'WG_833',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'PRESTINE , EUCALYPTUS COAL',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_834,
      name:'WG_834',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'SPECTORA , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_835,
      name:'WG_835',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'SPECTORA , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_836,
      name:'WG_836',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EARTHY NOYER, SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_837,
      name:'WG_837',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EARTHY NOYER , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_838,
      name:'WG_838',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EARTHY NOYER , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_839,
      name:'WG_839',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'EARTHY NOYER , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_840,
      name:'WG_840',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'WOODY DOGLER OAK , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_841,
      name:'WG_841',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'WOODY DOGLER OAK',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_842,
      name:'WG_842',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'HONNE HIGLO , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_843,
      name:'WG_843',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'HONNE HIGLO , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_844,
      name:'WG_844',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'GOLDEN TEAK , SLATO GREY',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_846,
      name:'WG_846',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'WALNET BURST',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    },
    {
      img:wg_847,
      name:'WG_847',
      width:'27, 30, 32, 33, 36, 38',
      height:'72, 75, 78, 81, 84',
      thickness:'32, 35, 38',
      finish:'READY TO FIT',
      type:'Laminate Door, Textured Laminate Door, Wood Color Laminate door',
      laminate:'WALNET BURST',
      usage:'Interior',
      series:'Lamorous Rich',
      description:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",

    }
         
        
    
  ]

  // solid_white_eco 
  const solid_white_eco = [
      {
        img:wg_751,
        name:'WG_751',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_752,
        name:'WG_752',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_753,
        name:'WG_753',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_754,
        name:'WG_754',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_756,
        name:'WG_756',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_760,
        name:'WG_760',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_761,
        name:'WG_761',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_762,
        name:'WG_762',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
      {
        img:wg_763,
        name:'WG_763',
        width:'27, 30, 32, 33, 36, 38, 42, 48',
        height:'72, 75, 78, 81, 84, 90, 96',
        thickness:'32, 35, 40',
        finish:'RAW',
        primer_color:'White',
        type:'White Doors, Primer Doors, Paintable Doors',
        usage:'Interior & Exterior',
        series:'Solid White Eco',
        description:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      }, 
  ]

  // solid_white_rich 
  const solid_white_rich = [
    {
      img:wg_1701,
      name:'WG_1701',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      primer_color:'White',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1702,
      name:'WG_1702',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1703,
      name:'WG_1703',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1704,
      name:'WG_1704',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1705,
      name:'WG_1705',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1706,
      name:'WG_1706',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1708,
      name:'WG_1708',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1710,
      name:'WG_1710',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
    {
      img:wg_1711,
      name:'WG_1711',
      width:'27, 30, 32, 33, 36, 38, 42, 48',
      height:'72, 75, 78, 81, 84, 90, 96',
      thickness:'32, 35, 40',
      finish:'RAW',
      primer_color:'White',
      type:'White Doors, Primer Doors, Paintable Doors',
      usage:'Interior & Exterior',
      series:'Solid White Rich',
      description:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
    },
  ]

  const flush_door = [
   
  ]


  // doors 
  const doors = [
  {  name:"timbor",
     collections:"1",
     banner:[
       {
        name:'teak_acacia_collection',
        image:timbor_acacia_banner,
        summary:'This brings to you a form of enlargement of beauty and allure, the path of classic decor into your space effortlessly withthe emblem of supremacy. Experience in design results in tasteful representation and grouping of statementsthat are amusing to ordinary minds.',
        products:timber_acia,
        full_screen:false
       }
     ]
  },
  {name:"ven_decor",collections:"4",
  banner:[
    {
     name:'ven_decor_legend',
     image:ven_decor_legend_banner,
     summary:'Nature has its own way of reflecting beauty reflected in the wild and yetfeels comfortable. WudGres presents projecting nature into a door evolved from the wilderness to hold the power of the wild to wrap your space into rowness.',
     products:vendecor_legend,
     full_screen:true
    },
    {
      name:'ven_decor_lavish',
      image:ven_decor_lavish_banner,
      summary:"Sculpt a revolution and uplift your space. Bring home pieces of decor that reflect your taste and character to define the space that's yours. It meets the eyes and that cannot be movedaway. Peculiar in design, Unique in its appeal, WudGres produces pieces of decor to intervene with the mundane and make your daily life into a story full of drama and creativity.",
      products:vendecor_lavish,
      full_screen:true
     },
     {
      name:'ven_decor_elite',
      image:ven_decor_elite_banner,
      summary:'Exhibit the unique act of art and science coming together to create exceptionally crafted doors. The surface you will love to feel and the design that will be yourdearest muse are here to escalate your lifestyle and reinvent your inner persona toflaunt it withpoise.',
      products:vendecor_elite,
      full_screen:true
     },
     {
      name:'ven_decor_rich',
      image:ven_decor_rich_banner,
      summary:"Portrait your space beyond perfect and let's get lost in your space full of organic textures a n d complexions. A unique collection of creative veneers with intricate grains and rich colors for a one-of-a-kind natural look in any project. The warmth, beauty, and individuality of natural wood veneer are unsurpassed by any other natural material.",
      products:vendecor_rich,
      full_screen:false
     }
  ]},
  {name:"teak_veneer",collections:"1", banner:[
    {
     name:'teak_veneer_collection',
     image:teak_veener_banner,
     summary:'Every body loves the touch and feel of  wood . To know the power of detail is to understand the power of finesse in design. The WudGres Teak Veneer Series is for those who want to enjoy the textures and feel the strength of wood but want it to be lighteron their pocket.',
     products:teak_vener,
     full_screen:true
    },
  ]},
  {name:"lamorous",collections:"4",
  banner:[
    {
     name:'lamorous_elite',
     image:lamourous_elite_banner,
     summary:"Bring the calm of sight satisfaction to your space. Alluring designs and patterns are here to woo onlookers with a sense of productivity with clarity. Tune your abstract lifestyle with the WudGres Lamorous Elite Series. It's a perfect pick for those looking for different combinations. This creation will undoubtedly mesmarize the onlookers at its first glance.",
     products:lamours_elite,
     full_screen:true
    },
    {
      name:'lamorous_rich',
      image:lamourous_rich_banner,
      summary:"Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the Balance the zeal of your space with these refined craftsmen's arts. That adds a unique mixture of originality and panache to your life. The effects and feel ofstone and wood are what everyoneloves to touch, and the look of them never ages. That is what you get in the WudGres Lamorous Rich Series ofDoors and at WudGres, we know your taste.",
      products:lamours_rich,
      full_screen:true
     },
     {
      name:'lamorous_prime',
      image:lamourous_prime_banner,
      summary:'Display your harmony with the comfort zone inside your space with sophistication and grace, designed to energise the outlandish lifestyle. These vibrant hues will enchant you and speak the language that translates the future version of beauty into reality.',
      products:lamours_prime,
      full_screen:true
     },
     {
      name:'lamorous_eco',
      image:lamourous_eco_banner,
      summary:'Fuse your space with natural shades that are soothing to your senses. This will definitely nourish your hectic lifestyle with tranquility. Tailor your space and weave togethera view of perfection with the ink of timelessness, and put together a story that timea n d again will speak of your presence.',
      products:lamours_eco,
      full_screen:true
     },
  ]},
  {name:"metalem",collections:"1",
  banner:[
    {
     name:'metalem_collection',
     image:metalem_banner,
     summary:"Doors play a very significant role ni modern living. WudGres' Metalem series will undoubtedly satisfy the desire to install a luxury door that is well-built and has added strength. Magnificent in Poise and majestic with this minimalistic design,a fresh contemporary take on classic designs.",
     products:metalem,
     full_screen:false
    },
  ]},
  {name:"espial",collections:"1",
  banner:[
    {
     name:'espial_collection',
     image:espial_banner,
     summary:"The very taste of elegance and fashion has become more defining. The reflective styles of this collection are very seductive and enchanting, making everyone fall for them. Luxury is an ease and a comfort to explore in order to find solaceasacompanion.",
     products:espial,
     full_screen:false
    },
  ]},
  {name:"divine",collections:"1",
  banner:[
    {
     name:'divine_collection',
     image:divine_banner,
     summary:"Bring divinity to your home with WudGres Divine Series doors. They are always ready to greet you at your divine place with ancient and contemporary designs that have a soft and smooth finish to give you a true divine feeling.",
     products:devine,
     full_screen:false
    },
  ]},
  {name:"embozz",collections:"1",
  banner:[
    {
     name:'embozz_collection',
     image:embozz_banner,
     summary:"Inspired by Egyptian, Floral and Modern Art, The WudGres Embozz series is flawless in its design and finish. This is why people consider each door to be a masterpiece. A masterpiece that gives them a better life. Rule the world of decor through this piece of art at work for you.",
     products:embozz,
     full_screen:false
    },
  ]},
  {name:"lamina",collections:"2",
  banner:[
    {
     name:'lamina_rich',
     image:lamina_rich_banner,
     summary:"Brass ornaments have always been adding beauty to the interiors since older civilization and the same is the case with the Lamina Rich collection of WudGres. The fusion of brass articles on foux wood grains leaves an everlasting impression and this combination never ages with time.",
     products:lamina_rich,
     full_screen:false
    },
    {
      name:'lamina_eco',
      image:lamina_eco_banner,
      summary:"The gorgeous texture has the same flawless design; you just can't make out the difference. WudGres Lamina Series is designed to be stunning, just lighter in appearance and naturally more durable, but everyone claims it gives them a trendy life.",
      products:lamina_eco,
      full_screen:false
     },
    
  ]},
  {name:"solid white",collections:"2",
  banner:[
    {
      name:'solid_white_rich_collection',
      image:solid_white_rich_banner,
      summary:"The whites are added with beautiful depth and yet calm. The solid white rich are made for the elite of society. These are mastered to perfection, making them mighty and ageless. With so many designs out there, make your space hold together the designs of white, which are rare. Own a work of art that communicate through restraints and hypnotizes.",
      products:solid_white_rich,
      full_screen:false
     },
     {
      name:'solid_white_eco_collection',
      image:solid_white_eco_banner,
      summary:"White is the most complete of all colors. It is innocent and pure. It is free and brilliant. It symbolises clarity and light. A room in white is good for the soul and is neither clinical nor boring. no matter what style of living is preferred. A door glowing with finess is enough to make a statement that is strong and unique. WudGres offers a hugevariety of sturdy white primeddoors.",
      products:solid_white_eco,
      full_screen:false
     }
   ]
  },
  {name:"flush_doors",collections:"1",
  banner:[
    {
     name:'Flush Door',
     image:flush_doors_banner,
     summary:"WudGres Flush Doors are simple interior and exterior doors that feature clean facing on both sides of the construction. While the flush door is more closely associated with function rather than style, this plain sort of door lends itself well to a number of room designs where the desire is to highlight other elements in the space. Doors of this type will have a solid core. The typical Flush doors is manufactured by using two sections of plywood that are attached to each side of wooden frame. While the interior of the door is made of solid hardwood battens. The frames in WudGres flush doors are of 100% Seasoned Hardwood. These flush doors are made in Boiling Water Resistant (BWR) grade, duly bonded with Fortified Phenol Formaldehyde Synthetic Resin.",
     products:flush_door,
     full_screen:false
    },
  ]},
 ]

  
  // disclamer 
  const disclamer = [
      {
        sl_no:1,
        test:'Density, Kg/m3',
        method:'IS: 1734',
        value:'500-700'
      },
      {
        sl_no:2,
        test:'Water Absorption, 2 hours, %',
        method:'IS: 2380',
        value:'< 0.2'
      },
      {
        sl_no:3,
        test:'Water Absorption, 24 hours, %',
        method:'IS: 2380',
        value:'< 1'
      },
      {
        sl_no:4,
        test:'Hardness, Shore D',
        method:'ASTM D 2240',
        value:'> 55'
      },
      {
        sl_no:5,
        test:'Tensile Strength, N/mm2',
        method:'IS: 1734',
        value:'> 11'
      },
      {
        sl_no:6,
        test:'Elongation at Break, %',
        method:'IS: 1734',
        value:'> 10'
      },
      {
        sl_no:7,
        test:'Modulus of Rupture N/mm2',
        method:'IS: 1734',
        value:'> 20'
      },
      {
        sl_no:8,
        test:'Modulus of Elasticity N/mm2',
        method:'IS: 1734',
        value:'> 1500'
      },
      {
        sl_no:9,
        test:'Screw Withdrawal Strength, N',
        method:'IS: 1734',
        value:'> 1500'
      },
      {
        sl_no:10,
        test:'Nail Withdrawal Strength, N',
        method:'IS: 1734',
        value:'> 150'
      },
  ]

  // disclamer1 
  const disclamer1 = [
    {
      sl_no:1,
      test:'Density,',
      unit:'Kg/m3',
      result:'0.9'
    },
    {
      sl_no:2,
      test:"Hardness - Shore 'D'",
      unit:'-',
      result:'0.9'
    },
    {
      sl_no:3,
      test:'Water Absorption, 24 hours,4 hours',
      unit:'%',
      result:'0.15,0.32'
    },
    {
      sl_no:4,
      test:'a. Tensile Strength at break b. Elongation at break',
      unit:'Kg/cm2%',
      result:'275,12'
    },
    {
      sl_no:5,
      test:'Screw Withdrawal Strength kg. face',
      unit:'249',
      result:'5,266'
    },
    {
      sl_no:6,
      test:'Nail Withdrawal Strength kg. face',
      unit:'63.2',
      result:'6,87.5'
    },
    {
      sl_no:7,
      test:'Izod Impact',
      unit:'J/M',
      result:'20.1'
    },
    {
      sl_no:8,
      test:'Chirpy Impact',
      unit:'KJ/m2',
      result:'50.0'
    },
    {
      sl_no:9,
      test:'Flexural Strength',
      unit:'Mpa',
      result:'13.1'
    },
    {
      sl_no:10,
      test:'Flexural Modulus',
      unit:'Mpa',
      result:'686'
    },
    {
      sl_no:11,
      test:'Flammability',
      unit:'-',
      result:'Match with VO'
    },
    {
      sl_no:12,
      test:'Compressive Strength',
      unit:'Kg/cm2',
      result:'100'
    },
  ]


  return (
    <div className='w-full px-0  overflow-x-hidden sm:px-0 mx-auto md:w-9/12 py-4'>

        {/* {state === null &&
         <div className='grid grid-cols-3  md:grid-cols-5 items-center justify-center mx-auto mb-10'>
          <h6 className={`text-[13px] p-2 mx-2 mb-2 md:mb-0 text-center cursor-pointer border border-[#cbab75] ${step === 1 && 'bg-[#cbab75]'}`} onClick={()=>{setstep(1);navigator('/products/doors')}} >DOORS</h6>
          <h6 className={`text-[13px] p-2 mx-2 mb-2 md:mb-0 text-center cursor-pointer border border-[#cbab75] ${step === 2 && 'bg-[#cbab75]'}`} onClick={()=>{setstep(2);navigator('/products/wpc')}}>WPC</h6>
          <h6 className={`text-[13px] p-2 mx-2 mb-2 md:mb-0 text-center cursor-pointer border border-[#cbab75] ${step === 3 && 'bg-[#cbab75]'}`} onClick={()=>{setstep(3);navigator('/products/window_shutters')}}>WINDOW SHUTTERS</h6>
          <h6 className={`text-[13px] p-2 mx-2 mb-2 md:mb-0 text-center cursor-pointer border border-[#cbab75] ${step === 4 && 'bg-[#cbab75]'}`} onClick={()=>{setstep(4);navigator('/products/plywood')}}>PLYWOOD & BLACK BOARD</h6>
          <h6 className={`text-[13px] p-2 mx-2 mb-2 md:mb-0 text-center cursor-pointer border border-[#cbab75] ${step === 5 && 'bg-[#cbab75]'}`} onClick={()=>{setstep(5);navigator('/products/wood_frames')}}>ENG.. WOOD FRAMES</h6>
         </div>} */}

         {/* {step ===  1 &&  */}

         {path === undefined &&
        <div className='bg-typography  bg-contain py-10 '>
        <div className='relative '>
        <h6 className='font_thicker text-[50px] leading-tight  md:text-[90px] z-0 opacity-100 text-[#cbab75] mb-10 w-full text-center  after:-z-10 uppercase -top-[175%]  md:-left-[1%] md:-top-[255%]'>What we make </h6>


       

        </div>
        <div className='w-full  mx-auto'>
       
           

           <div className='md:mt-0 grid grid-cols-1 gap-2  md:grid-cols-5 justify-evenly mx-auto'>

          <div data-aos="fade-up" onClick={()=>navigator('/products/doors')} className='group bg-[#cbab75] h-[140px]  group cursor-pointer shadow-md  md:mb-0 w-[90%] mx-auto md:w-full p-2 mx-2 flex items-center justify-center'>
          <h6 className='uppercase text-center transition-all duration-300 text-white uppercase  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>Doors</h6>
          </div>
          <div data-aos="fade-up" onClick={()=>navigator('/products/wpc')} className='group bg-[#cbab75] h-[140px] w-[90%] mx-auto md:w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
          <h6  className='uppercase text-center transition-all duration-300 text-white uppercase  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>WPC</h6>
          </div>
          <div data-aos="fade-up" onClick={()=>navigator('/products/shutters')} className='group bg-[#cbab75] h-[140px]  w-[90%] mx-auto md:w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
          <h6  className='uppercase text-center transition-all duration-300 text-white uppercase  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>Window Shutters</h6>
          </div>
          <div data-aos="fade-up" onClick={()=>navigator('/products/board')} className='group bg-[#cbab75] h-[140px]  w-[90%] mx-auto md:w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
          <h6  className='uppercase text-center transition-all duration-300 text-white uppercase  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>Plywood & Block Board</h6>
          </div>
          <div data-aos="fade-up" onClick={()=>navigator('/products/engineered')} className='group bg-[#cbab75] h-[140px]  w-[90%] mx-auto md:w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
          <h6  className='uppercase text-center transition-all duration-300 text-white uppercase  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>ENG. WOOD FRAMES</h6>
          </div>
          {/* <div data-aos="fade-up" onClick={()=>navigator('/products/flush_doors')} className='group bg-[#cbab75] h-[140px]  w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
          <h6  className='uppercase text-center transition-all duration-300 text-white uppercase  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>FLUSH DOORS</h6>
          </div> */}
          

</div>


          
        </div>


        </div>}


         
         {(path === 'doors' && path1 === undefined) &&
           <div className='px-8 grid grid-cols-2 mx-2 md:grid-cols-6 gap-2 md:gap-5 place-content-center place-items-center border-b border-gray-100 mb-4'>
             {doors?.map((v)=>(<div onClick={()=>navigator(`/products_category/${v?.name}`,{state:{data:v}})}  className={`group bg-[#cbab75] mx-2 mb-2 group  flex items-center justify-center rounded-[2px] shadow-lg w-[100%] h-[150px]  text-[20px] text-white font_thicksss cursor-pointer px-1 text-center  `}>
                <div>
                  <h6 className='uppercase  text-center mt-4 font_expand'>{v?.name?.replaceAll('_',' ')}</h6>
                  <h6 className='opacity-0 font_expand text-center transition-all duration-300 group-hover:opacity-100 transition delay-100 duration-300 ease-in text-[14px] font_thick'>{v?.collections} Collections</h6>
                </div>
              </div>))}
         </div>}
            
      
       
        {/* step 2 */}
        {step === 2 &&
        <>
        <div className='w-[90%] relative md:w-full mx-auto'>
        {(path === 'wpc' && path1 === undefined) &&
        <>
        <h6 className='text-[40px] md:text-[60px] absolute right-0 -top-10 font_thicker text-[#cbab75]'>WPC - DOORS | FRAMES | BOARDS</h6>

        {/* <h6 className='text-center text-[18px]  font-[900] mb-10'>WPC - DOORS | FRAMES | BOARDS</h6> */}
        <h6 className='text-[14px] pt-36 md:pt-14 font_thick mb-2'>The need for Timber has been growing rapidly, leading to less of Green Cover. Also the fact is the use of Timber cannot be stopped completely, but the way has to be found to minimise its use for the sake of our next generations. Keeping this in mind WUDGRES introduces WOODLORD - WPC Doors, WPC Frames, WPC Boards & several other products made from saw dust ( wood powder) & virgin or recycled plastic composites. WOODLORD WPC is durable and long lasting, This being 100% recyclable makes it a Environment Friendly Product.</h6>  
        <h6 className='text-[14px] font_thick mb-2'>Welcome to WOODLORD – A world of Eco-Friendly products.</h6>  

        <h6 className='text-center mt-5 text-[24px] font-[900] mb-10'>Welcome to <span className='text-[#cbab75]'>WOODLORD</span></h6>
        <h6 className='text-[14px] font_thick text-center mb-2'>A World of Eco-Friendly products.</h6>



        <div className='w-[80%] grid grid-cols-1 gap-2 md:ml-[14%] mt-10 md:grid-cols-2 justify-evenly mx-auto'>

           <div data-aos="fade-up" onClick={()=>navigator('/products/wpc/wpc-doors')} className='group bg-[#cbab75] h-[140px] w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
            <h6 className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>WPC DOORS</h6>
            </div>

            <div data-aos="fade-up" onClick={()=>navigator('/products/wpc/wpc-frames')} className='group bg-[#cbab75] h-[140px] w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
             <h6 className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>WPC FRAMES</h6>
            </div>

            {/* <div data-aos="fade-up" onClick={()=>navigator('/products/wpc/wpc-boards')} className='group bg-[#cbab75] h-[140px] w-full p-2 mx-2 flex items-center justify-center cursor-pointer shadow-md md:mb-0'>
              <h6 className='uppercase text-center transition-all duration-300 text-white  delay-50 group-hover:scale-125 mt-2 text-[25px] font-[700]'>WPC BOARDS</h6>
            </div> */}
        </div>  
        </>}

        {path1 === 'wpc-doors' &&
        <>
        <h6 className='text-[18px] mt-5 font-[800]'>WUDGRES WPC DOORS</h6>
        <div className='block md:flex mt-10'>
            <img alt="_logo" className='-mt-5 md:mt-0 md:-ml-20 md:min-w-[50%] md:max-w-[50%] h-[500px] object-contain' src="https://wudgres.s3.ap-south-1.amazonaws.com/wudgres_images/other/wpc_doors.png" />
            <div className='ml-0 md:ml-10 mt-5  md:mt-0'>
                <h6 className='text-[15px] mt-5 font-[800] mb-3'>DESCRIPTION </h6>
                <h6 className='text-[14.5px] font_thick'>The need for Timber has been growing rapidy, leading to less of green cover, also the fact is the use of Timber cannot be stopped completely, but the way has to be found to minimise its use for the sake of our next generations. Keeping this in mind WudGres introduces WOODLORD - WPC Doors, WPC Frames, WPC Boards & several other products made from saw dust (wood powder) & virgin or recycled plastic composites. WOODLORD WPC is durable and long lasting. This being 100% recyclable makes it a environment friendly product.</h6>
                <h6 className='text-[15px] font-[800] mt-4'>WUDGRES WPC DOORS - ECO</h6>

                <div className='flex items-center mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t border-b text-center p-1 border-l  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-24 border-t border-b text-center p-1 border-l  mb-2'>THICKNESS </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-t border-b text-center p-1 border-l  mb-2'>HEIGHT </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-t border-b text-center p-1 border-l border-r mb-2'>WIDTH </h6>
                </div>  
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l  mb-2'>1 </h6>
                   <h6 className='text-[12px] font-[400] w-24 border-b text-center p-1 border-l  mb-2'>  28mm    </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-b text-center p-1 border-l  mb-2'> 78″,81″.    </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-b text-center p-1 border-l border-r mb-2'>27″,30″ </h6>
                </div> 
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l  mb-2'>2 </h6>
                   <h6 className='text-[12px] font-[400] w-24 border-b text-center p-1 border-l  mb-2'>  24mm    </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-b text-center p-1 border-l  mb-2'> 78″,81″.    </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-b text-center p-1 border-l border-r mb-2'>27″ </h6>
                </div>  

                <h6 className='text-[15px] font-[800] mt-5'>WUDGRES WPC DOORS - RICH</h6>
                <div className='flex items-center mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t border-b text-center p-1 border-l  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-24 border-t border-b text-center p-1 border-l  mb-2'>THICKNESS </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-t border-b text-center p-1 border-l  mb-2'>HEIGHT </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-t border-b text-center p-1 border-l border-r mb-2'>WIDTH </h6>
                </div>  
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l  mb-2'>1 </h6>
                   <h6 className='text-[12px] font-[400] w-24 border-b text-center p-1 border-l  mb-2'>  30mm    </h6>
                   <h6 className='text-[12px] font-[400] w-20 border-b text-center p-1 border-l  mb-2'> 78″,81″.84    </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-b text-center p-1 border-l border-r mb-2'>27″,30″,32″,36″ </h6>
                </div> 

                <h6 className='text-[15px] font-[800] mt-5'>DISCLAMER </h6>
                <h6 className='text-[14.5px] font_thick'>Colors of the products shown in Website are only for indication purpose, actual product may vary.</h6>


                <div className='flex items-stretch mt-2 border-t border-b'>
                   <h6 className='text-[12px] font-[400] w-14  text-center border-l p-1  '>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-48  text-center border-l p-1'>TEST </h6>
                   <h6 className='text-[12px] font-[400] w-28  text-center border-l p-1'>TEST METHOD </h6>
                   <h6 className='text-[12px] font-[400] w-28  text-center border-l border-r  p-1'>VALUE </h6>
                </div>  

                {disclamer?.map((d)=>(
                <div className='flex items-stretch  border-l border-r border-b'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1  '>{d?.sl_no}</h6>
                   <h6 className='text-[12px] font-[400] w-48 border-b text-center p-1 border-l '> {d?.test}</h6>
                   <h6 className='text-[12px] font-[400] w-28 border-b text-center p-1 border-l '> {d?.method}  </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-b text-center p-1 border-l'>{d?.value} </h6>
                </div> 
                ))}

                <h6 className='text-[11.5px] mt-4 font_thick'>*All information mentioned above is based on typical sample, is accurate to the best of our knowledge, and is provided without liability or commitment. As WOODLORD has no control over which other use of this material, it does not give guarantee that the same result are obtained, as mentioned here. Each use of material should make their own test in their own local condition to confirm the suitability of our product for their particular use. Technical Specifications are Subject to Change.</h6>


                
            </div>
        </div>
        </>}

        {path1 === 'wpc-frames' &&
        <>
        <h6 className='text-[18px] mt-5 font-[800]'>WUDGRES WPC FRAMES</h6>
        <div className='block md:flex mt-10'>

            {/* <img alt="_logo" className='-mt-10 md:mt-0 w-full md:w-3/5 h-[240px] object-contain' src="https://wudgres.s3.ap-south-1.amazonaws.com/other_collection/wpc/2.png" /> */}
            <div className='md:w-[200vw]'>
            <img alt="_logo" className='-mt-10 md:mt-0 w-full h-[240px] object-contain' src={wpc1} />
            <img alt="_logo" className='-mt-10 md:mt-0 md:mt-0 w-full h-[240px] object-contain' src={wpc2} />
            </div>
            <div className='ml-0 md:ml-10 mt-5  md:mt-0'>
                
                <h6 className='text-[15px] mt-5 font-[800] mb-3'>DESCRIPTION</h6>
                <h6 className='text-[14.5px] font_thick'>The need for Timber has been growing rapidy, leading to less of green cover, also the fact is the use of Timber cannot be stopped completely, but the way has to be found to minimise its use for the sake of our next generations. Keeping this in mind WudGres introduces WOODLORD - WPC Doors, WPC Frames, WPC Boards & several other products made from saw dust (wood powder) & virgin or recycled plastic composites. WOODLORD WPC is durable and long lasting. This being 100% recyclable makes it a environment friendly product.</h6>
                
                <h6 className='text-[15px] mt-5 font-[800] mb-3'>DISCLAMER</h6>
                <h6 className='text-[14.5px] font_thick'>Colors of the products shown in Website are only for indication purpose, actual product may vary.</h6>

                <h6 className='text-[13px] font-[600] mt-5'>WUDGRES WPC DOORS - RICH 850D</h6>
                <div className='flex items-stretch mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t border-b text-center p-1 border-l  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t border-b text-center p-1 border-l border-r  mb-2'>DESCRIPTION </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t border-b text-center p-1 border-l border-r  mb-2'>AVERAGE DENS </h6>
                </div>  
                <div className='flex items-stretch -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l mb-2'>A </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> FRAME SECTION 60X30</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> 0.350 KG / RFT</h6>
                </div> 
                <div className='flex items-stretch -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l mb-2'>B </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> FRAME SECTION 75X50</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> 0.875 KG / RFT</h6>
                </div> 
                <div className='flex items-stretch -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l mb-2'>C </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> FRAME SECTION 100X63</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> 1.550 KG / RFT</h6>
                </div> 
                <div className='flex items-stretch -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l  mb-2'>D </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> FRAME SECTION 125X63</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> 1.950 KG / RFT</h6>
                </div> 

                <div className='flex items-stretch mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t border-b text-center p-1 border-l  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-60 border-t border-b text-center p-1 border-l  mb-2'>TEST </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-t border-b text-center p-1 border-l  mb-2'>UNIT </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-t border-b text-center p-1 border-l border-r mb-2'>RESULT </h6>
        </div>  

        {disclamer1?.map((d)=>(
        <div className='flex items-stretch -mt-2  h-auto'>
            <h6 className='text-[12px] font-[400] border-b truncate w-14  text-center p-1 border-l  mb-2'>{d?.sl_no}</h6>
            <h6 className='text-[12px] font-[400] border-b truncate w-60  text-center p-1 border-l  mb-2'> {d?.test}</h6>
            <h6 className='text-[12px] font-[400] border-b truncate w-28  text-center p-1 border-l  mb-2'> {d?.unit}  </h6>
            <h6 className='text-[12px] font-[400] border-b truncate w-28  text-center p-1 border-l border-r mb-2'>{d?.result} </h6>
        </div> 
        ))}

        <div className='flex items-stretch mt-2'>
            <h6 className='text-[12px] font-[400] w-60 border-t border-b text-center p-1 border-l  mb-2'>SINGLE DOOR FRAME </h6>
            <h6 className='text-[12px] font-[400] w-48 border-t border-b text-center p-1 border-r border-l  mb-2'>DOUBLE DOOR FRAME </h6>
        </div>

        <div className='flex items-stretch -mt-2'>
            <h6 className='text-[12px] font-[400] w-60 border-b text-center p-1 border-l  mb-2'>60x30, 75x50, 100x50, 100x62, 125x62 </h6>
            <h6 className='text-[12px] font-[400] w-48 border-b text-center p-1 border-l border-r mb-2'>125x62</h6>
        </div>
            </div>  
        </div>
        
        </>}

        {path1 === 'wpc-boards' &&
        <>
        <h6 className='text-[18px] mt-5 font-[800]'>WUDGRES WPC FRAMES</h6>
        <div className='block md:flex mt-10'>
            
            {/* <img alt='_logo'  className='-mt-10 md:mt-0 w-full md:w-3/5 h-[240px] object-contain' src={wpc1} />  */}
            <img alt="_logo" className='-mt-10 md:mt-0 w-full md:w-3/5 h-[240px] object-contain' src="https://wudgres.s3.ap-south-1.amazonaws.com/other_collection/wpc/2.png" />
            <div className='ml-0 md:ml-10 mt-5  md:mt-0'>
                
                <h6 className='text-[15px] mt-5 font-[800] mb-3'>DESCRIPTION</h6>
                <h6 className='text-[14.5px] font_thick'>The need for Timber has been growing rapidy, leading to less of green cover, also the fact is the use of Timber cannot be stopped completely, but the way has to be found to minimise its use for the sake of our next generations. Keeping this in mind WudGres introduces WOODLORD - WPC Doors, WPC Frames, WPC Boards & several other products made from saw dust (wood powder) & virgin or recycled plastic composites. WOODLORD WPC is durable and long lasting. This being 100% recyclable makes it a environment friendly product.</h6>
                
              
                <h6 className='text-[13px] font-[600] mt-5'>WOODLORD WPC BOARD - ECO 500D</h6>
                <div className='flex items-center mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t  text-center p-1 border-l border-r  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t  text-center p-1 border-l border-r  mb-2'>THICKNESS </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t  text-center p-1 border-l border-r  mb-2'>WIDTH </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t  text-center p-1 border-l border-r  mb-2'>HEIGHT </h6>
                </div>  
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l border-r  mb-2'>1 </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> 6 MM</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>96"</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>48"</h6>
                </div> 
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l border-r  mb-2'>2 </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>12 MM</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>96"</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>48"</h6>
                </div> 
                <div className='flex items-center -mt-2'>
                <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l border-r  mb-2'>3 </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>18 MM</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>96"</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>48"</h6>
                </div> 

                <h6 className='text-[13px] font-[600] mt-5'>WOODLORD WPC BOARD - RICH 600D</h6>
                <div className='flex items-center mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t border-b text-center p-1 border-l border-r  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t border-b text-center p-1 border-l border-r  mb-2'>THICKNESS </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t border-b text-center p-1 border-l border-r  mb-2'>WIDTH </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-t border-b text-center p-1 border-l border-r  mb-2'>HEIGHT </h6>
                </div>  

                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l border-r  mb-2'>1 </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'> 6 MM</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>96"</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>48"</h6>
                </div> 
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l border-r  mb-2'>2 </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>12 MM</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>96"</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>48"</h6>
                </div> 
                <div className='flex items-center -mt-2'>
                <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l border-r  mb-2'>3 </h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>18 MM</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>96"</h6>
                   <h6 className='text-[12px] font-[400] w-40 border-b text-center p-1 border-l border-r  mb-2'>48"</h6>
                </div> 

                <h6 className='text-[15px] mt-5 font-[800] mb-3'>DISCLAMER</h6>
                <h6 className='text-[14.5px] font_thick'>Colors of the products shown in Website are only for indication purpose, actual product may vary.</h6>

              

        

        <div className='flex items-center mt-2'>
            <h6 className='text-[12px] font-[400] w-60 border-t border-b text-center p-1 border-l  mb-2'>SINGLE DOOR FRAME </h6>
            <h6 className='text-[12px] font-[400] w-48 border-t border-b text-center p-1 border-r border-l  mb-2'>DOUBLE DOOR FRAME </h6>
        </div>

        <div className='flex items-center -mt-2'>
            <h6 className='text-[12px] font-[400] w-60 border-b text-center p-1 border-l  mb-2'>60x30, 75x50, 100x50, 100x62, 125x62 </h6>
            <h6 className='text-[12px] font-[400] w-48 border-b text-center p-1 border-l border-r mb-2'>125x62</h6>
        </div>

        <div className='flex items-center mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-t border-b text-center p-1 border-l  mb-2'>SL NO </h6>
                   <h6 className='text-[12px] font-[400] w-48 border-t border-b text-center p-1 border-l  mb-2'>TEST </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-t border-b text-center p-1 border-l  mb-2'>TEST METHOD </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-t border-b text-center p-1 border-l border-r mb-2'>VALUE </h6>
                </div>  

                {disclamer?.map((d)=>(
                <div className='flex items-center -mt-2'>
                   <h6 className='text-[12px] font-[400] w-14 border-b text-center p-1 border-l  mb-2'>{d?.sl_no}</h6>
                   <h6 className='text-[12px] font-[400] w-48 border-b text-center p-1 border-l  mb-2'> {d?.test}</h6>
                   <h6 className='text-[12px] font-[400] w-28 border-b text-center p-1 border-l  mb-2'> {d?.method}  </h6>
                   <h6 className='text-[12px] font-[400] w-28 border-b text-center p-1 border-l border-r mb-2'>{d?.value} </h6>
                </div> 
                ))}

                <h6 className='text-[11.5px] mt-4 font_thick'>*All information mentioned above is based on typical sample, is accurate to the best of our knowledge, and is provided without liability or commitment. As WOODLORD has no control over which other use of this material, it does not give guarantee that the same result are obtained, as mentioned here. Each use of material should make their own test in their own local condition to confirm the suitability of our product for their particular use. Technical Specifications are Subject to Change.</h6>


            </div>  
        </div>
        
        </>}

           

        {path1 !== undefined &&
        <>
        <h6 className='text-[13px] font_thick mt-5'>For Product More Information. Please download the wudgres app which is just designed for product information and purchase.</h6>
        <div className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

              </div>
        </>}
        
        </div>
        </>} 
        
        {/* step 3 */}
        {/* {step === 3 && */}
        {(path === 'shutters' && path1 === undefined) &&
        <div className='relative'>
            <h6 className='text-[70px] absolute right-0 -top-10 font_thicker text-[#cbab75]'>WINDOW SHUTTERS</h6>

            {/* <h6 className='text-center text-[18px] font-[900]  mb-10'>WINDOW SHUTTERS</h6> */}

            <div className='mx-10 pt-16 md:mx-0 md:flex items-center'>
              <div className='grid md:min-w-[40%] mt-20 md:mt-0 md:max-w-[40%] grid-cols-2 md:grid-cols-2 gap-2'>

                  <div>
                      <img alt="_img1" src="https://wudgres.s3.ap-south-1.amazonaws.com/shutter/1.png" className='object-contain w-full h-[250px]' />
                      <h6 className='font-[400] text-[13px] mt-2 text-center'>WG-W01</h6>
                  </div>
                  <div>
                      <img alt="_img1" src="https://wudgres.s3.ap-south-1.amazonaws.com/shutter/2.png" className='object-contain w-full h-[250px]' />
                      <h6 className='font-[400] text-[13px] mt-2 text-center'>WG-W02</h6>
                  </div>
                  <div>
                      <img alt="_img1" src="https://wudgres.s3.ap-south-1.amazonaws.com/shutter/3.png" className='object-contain w-full h-[250px]' />
                      <h6 className='font-[400] text-[13px] mt-2 text-center'>WG-W03</h6>
                  </div>
                  <div>
                      <img alt="_img1" src="https://wudgres.s3.ap-south-1.amazonaws.com/shutter/4.png" className='object-contain w-full h-[250px]' />
                      <h6 className='font-[400] text-[13px] mt-2 text-center'>WG-W04</h6>
                  </div>
              </div>
              <div className='mt-10 md:-mt-16 md:ml-10'>
                <h6 className='text-[14px] font_thick '>A very small but a very important item which adds beauty to the overall look of your house, A Window Shutter. There are so many design option in windows shutters like French Window, Indian Arch Window, etc. Despite of so many option available the customers are compelled to choose from two to three available options at Timber Shops. When customer has so many options for all the items that he needs for their home but not in window shutters. With WudGres Window Shutters you have endless options, customer can choose from designs available in the catalogue or if they have an other design in mind they can visit nearest STUDIO or EXCLUSIVE STORE and show the design they want. Our executives will co-ordinate with production team and make it ready for you.</h6>

                <h6 className='text-[14px] font_thick mt-5'>Key Features</h6>

                <h6 className='text-[14px] font_thick '>1. Available in Teak and Acacia</h6>
                <h6 className='text-[14px] font_thick '>2. Completely seasoned wood with moisture content of 5 to 8% in a Acacia and 12 to 15% in Teak. This results in less shrinkage and expansion.</h6>
                <h6 className='text-[14px] font_thick '>3. Available in customised Designs and Sizes.</h6>


                <h6 className='text-[13px] font_thick mt-5'>Note : Timber being a natural material Bending, Shrinkage and Expansion is a part of its life cycle process. Seasoning Timber reduces this issue but does not eliminate it completely. Window Shutter with more than 48" in height will have one or more Finger Joints.</h6>
                <h6 className='text-[13px] font_thick mt-5'>For Product More Information. Please download the wudgres app which is just designed for product information and purchase.</h6>

                <div className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

              </div>
              </div>  
            </div>
        </div>}
        {/* } */}

        {/* step 4 */}
        {/* {step === 4 && */}
         {(path === 'board' && path1 === undefined) &&
          <div className='w-[90%] relative md:w-full mx-auto'>
              {/* <h6 className='text-center text-[18px] font-[900] mb-5'>WUDGRES PLYWOOD AND BLACK BOARD</h6> */}
              <h1 className='text-[40px] md:text-[70px] absolute left-5 md:left-0 md:right-0 -top-10 font_thicker text-[#cbab75]'>PLYWOOD & BLACK BOARD</h1>
              <h6 className='text-[14px] pt-24 md:pt-16 w-[90%] md:w-full mx-auto font_thick mb-5'>Quality has been a major issue in the plywood available in the market. New innovations and technologies were never used in upgrading the quality. At WudGres, with the introduction of latest machinery and bringing in latest manufacturing techniques, the production standards have been upgraded in bringing out the best Plywood and Block Board.</h6>
              <h6 className='text-[14px] w-[90%] md:w-full mx-auto font_thick mb-10'>WudGres has been at forefront of giving its customer the best quality at affordable rates, thus making the interiors economical.</h6>
              


              <h6 className='ml-2 font-[700]'>PLY WOOD</h6>
              <div className='grid grid-cols-1 mt-2 md:grid-cols-2 gap-4'>



                  <div>
                      <img alt="_img1" src={bwr1} className='object-cover w-[96%] ml-[2%]' />
                      <div className='mt-4 border w-[96%] ml-[2%]'>
                          <div className='text-[12px] flex border-b'>
                            <h6 className='font-[600] border-r   mr-2 p-1 min-w-[25%] max-w-[25%]'>GRADE :</h6>
                           <h6 className=' p-1'>BWR (MELAMINE BONDED)</h6></div>
                          <div className='text-[12px] flex border-b'>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>PLY CORE :</h6>
                            <h6 className=' p-1'> NEEM WOOD / EUCALYPTUS WOOD / POPLAR WOOD  IN PLYWOOD</h6>
                            </div>
                          <div className='text-[12px] flex border-b'>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>PLY FACE :</h6>
                            <h6 className=' p-1'> GURJAN</h6></div>
                          <div className='text-[12px] flex border-b'>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>SIZE (FT) :</h6>
                            <h6 className=' p-1'>8X4 , 7X4</h6>
                             </div>
                          <div className='text-[12px] flex '>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>THICKNESS :</h6>
                            <h6 className=' p-1'>6MM, 12MM, 18MM</h6>
                            </div>
                      </div>
                  </div>
                  <div>
                      <img alt="_img1" src={bwp1} className='object-cover  w-[96%] ml-[2%]' />
                      <div className='mt-4 border w-[96%] ml-[2%]'>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 min-w-[25%] p-1 max-w-[25%]'>GRADE :</h6>
                            <h6 className='p-1'> BWP (PHENOL BONDED)</h6></div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 min-w-[25%] p-1 max-w-[25%]'>PLY CORE :</h6> 
                            <h6 className='p-1'> NEEM WOOD / EUCALYPTUS WOOD / POPLAR WOOD  IN PLYWOOD</h6> </div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 min-w-[25%] p-1 max-w-[25%]'>PLY FACE :</h6> 
                            <h6 className='p-1'> GURJAN</h6> </div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 min-w-[25%] p-1 max-w-[25%]'>SIZE (FT) :</h6> 
                            <h6 className='p-1'>  8X4 , 7X4</h6> </div>
                          <div className='text-[12px] flex '>
                            <h6 className='font-[600] border-r  mr-2 min-w-[25%] p-1 max-w-[25%]'>THICKNESS :</h6> 
                            <h6 className='p-1'>  6MM, 12MM, 18MM</h6> </div>
                      </div>
                  </div>


              
              </div>


              <h6 className='ml-2 font-[700] mt-10'>BLACK BOARD</h6>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div>
                      <img alt="_img1" src="https://wudgres.s3.ap-south-1.amazonaws.com/wudgres_images/other/w3.png" className='object-cover opacity-90' />
                      <div className='mt-4 border w-[96%] ml-[2%]'>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>GRADE :</h6> 
                            <h6 className='p-1'>BWR (PHENOL BONDED)</h6></div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>CORE :</h6>
                            <h6 className='p-1'>NEEM WOOD / EUCALYPTUS WOOD / POPLAR WOOD  IN PLYWOOD</h6></div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>FILLING :</h6> 
                            <h6 className='p-1'>PINE WOOD </h6></div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>BOARD FACE :</h6> 
                            <h6 className='p-1'>GURJAN</h6></div>
                          <div className='text-[12px] flex border-b '>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>SIZE (FT) :</h6>
                            <h6 className='p-1'> 8X4 , 7X4</h6></div>
                          <div className='text-[12px] flex'>
                            <h6 className='font-[600] border-r  mr-2 p-1 min-w-[25%] max-w-[25%]'>THICKNESS :</h6>
                            <h6 className='p-1'> 19MM, 25MM</h6></div>
                      </div>
                  </div>
                  <div>
                      <img alt="_img1" src="https://wudgres.s3.ap-south-1.amazonaws.com/wudgres_images/other/w4.png" className='object-cover opacity-90' />
                      <div className='mt-4 border w-[96%] ml-[2%]'>
                          <div className='text-[12px] flex border-b'><h6 className='font-[600] border-r  mr-2 min-w-[25%] max-w-[25%] p-1'>GRADE :</h6><h6 className='p-1'> BWR (MR UREA FORMALDYHIDE BONDED)</h6> </div>
                          <div className='text-[12px] flex border-b'><h6 className='font-[600] border-r  mr-2 min-w-[25%] max-w-[25%] p-1'>CORE :</h6><h6 className='p-1'>NEEM WOOD / EUCALYPTUS WOOD  / POPLAR WOOD  IN PLYWOOD</h6> </div>
                          <div className='text-[12px] flex border-b'><h6 className='font-[600] border-r  mr-2 min-w-[25%] max-w-[25%] p-1'>FILLING :</h6><h6 className='p-1'> PINE WOOD </h6> </div>
                          <div className='text-[12px] flex border-b'><h6 className='font-[600] border-r  mr-2 min-w-[25%] max-w-[25%] p-1'>BOARD FACE :</h6><h6 className='p-1'> GURJAN</h6> </div>
                          <div className='text-[12px] flex border-b'><h6 className='font-[600] border-r  mr-2 min-w-[25%] max-w-[25%] p-1'>SIZE (FT) :</h6><h6 className='p-1'>  8X4 , 7X4</h6> </div>
                          <div className='text-[12px] flex'><h6 className='font-[600] border-r  mr-2 min-w-[25%] max-w-[25%] p-1'>THICKNESS :</h6> <h6 className='p-1'>19MM , 25MM</h6> </div>
                      </div>
                  </div>
              </div>    
              <h6 className='text-[13px] font_thick mt-5'>For Product More Information. Please download the wudgres app which is just designed for product information and purchase.</h6>

              <div className='mt-5 flex'>
                <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                  <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

                </div>
          </div>}

        {/* step 5  */}
        {/* {step === 5 && */}
        {(path === 'engineered' && path1 === undefined) &&
        <div className='w-[90%] relative md:w-full mx-auto'  >
        <h1 className='text-[70px] absolute right-0 -top-10 font_thicker text-[#cbab75]'>WOOD FRAME'S</h1>

        <div className='md:flex pt-40 md:pt-14'>
        <div className='w-full md:flex  items-start md:w-[50%]'>
            <img className='w-[100%]  h-[50vh]  md:h-[60vh] object-contain' src={woodframe1} />
            <img className='md:w-[80%] h-[80vh] -mt-48 md:-mt-32 md:ml-10  md:h-[60vh] object-contain' src={woodframe2} />
        </div>
        <div className='w-[90%] -mt-48  md:ml-56  md:mt-2  mx-auto '>
        <div className='mt-2 flex items-stretch  border-b border-l border-r border-t'>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r  py-1 '>SL NO  </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1  border-r  py-1 '>PARTICULAR </h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1   py-1 '>LENGTH (FT) </h6>
        </div>  
        <div className='flex items-stretch  border-b border-l border-r  '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1 py-1  border-r  '>A </h6>
            <h6 className='text-[12px] font-[400] w-60    text-center px-1 py-1  border-r  '>FRAME SECTION 4″x2″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1 py-1   '> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>B </h6>
            <h6 className='text-[12px] font-[400] w-60   text-center px-1 border-r  py-1'>FRAME SECTION 4″x3″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1  py-1'> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>C </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1 border-r  py-1'>FRAME SECTION 5″x3″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1  py-1'> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>D </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1 border-r  py-1'>FRAME SECTION 6″x3″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1 py-1'> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>E </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1 border-r  py-1'>FRAME SECTION 6″x4″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1  py-1'> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>F </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1 border-r  py-1'>FRAME SECTION 9″x3″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1  py-1'> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>G </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1 border-r  py-1'>FRAME SECTION 9″x4″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1   py-1'> 3′/6′/7′/8′</h6>
        </div> 
        <div className='flex items-stretch border-b border-l border-r '>
            <h6 className='text-[12px] font-[400] w-14  text-center px-1  border-r '>H </h6>
            <h6 className='text-[12px] font-[400] w-60  text-center px-1 border-r  py-1'>FRAME SECTION 12″x4″</h6>
            <h6 className='text-[12px] font-[400] w-28  text-center px-1   py-1'> 3′/6′/7′/8′</h6>
        </div> 

        <h6 className='text-[13px] font_thick mt-5'>For Product More Information. Please download the wudgres app which is just designed for product information and purchase.</h6>

        <div className='mt-5 flex'>
              <h6 onClick={()=>openExternalLink(2)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] text-center'>Google Play</h6>
                <h6 onClick={()=>openExternalLink(1)} className='transition delay-100 duration-200 ease-in border border-[#000] py-1 px-2.5 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[12px] w-[110px] ml-2 text-center'>App Store</h6>

              </div>

        </div>



        </div>

      
        </div>}

         

    </div>
  )
}

export default Products