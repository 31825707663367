import axios from 'axios'
import React, { useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import {BiError} from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
function Contact() {

  const navigator = useNavigate()
  const [data,setdata] =  useState({name:'',mobile:'',email:'',message:''})
  const [error,seterror] =  useState({name:'',mobile:'',email:'',message:''})

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  
  function submit(){
    if(!data.name){
      seterror({...error,name:'The name field is required*'})
    }else if(!data.mobile || data.mobile.length !== 10){
      seterror({...error,mobile:'Enter a valid 10 digit mobile no*'})
    }else if(!data.email){
      seterror({...error,email:'The email field is required*'})
    }else if(!data.message){
      seterror({...error,message:'The message field is required*'})
    }else{
      axios.post('https://wudnberg.com/api/admin/auth/service_query/',data)
      .then((res)=>{
        toast.success(res?.data?.data)
        setdata({name:'',mobile:'',email:'',message:''})
        seterror({name:'',mobile:'',email:'',message:''})
      }).catch((err)=>{
      })
    }
  }


  return (
    <>
    <div className='md:mt-0 grid place-items-center justify-items-center text-white bg-cover h-[230px] w-full bg-hero md:h-[350px] md:bg-cover bg-no-repeat bg-center'>
      <div>
        <h6 className='text-[30px] text-center tracking-[10px] font_expand'>GET IN TOUCH</h6>
        <h6 className='text-center tracking-wider text-gray-600 opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Contact </h6>
        <h6 className='text-[14px] font_thick text-center opacity-70'>We Like to solve our client's query at any time. </h6>

      </div>
    </div>
    <div className='relative w-full bg-map bg-no-repeat bg-contain bg-right-top px-10 sm:px-0 mx-auto md:w-8/12 py-10 mt-10'>
      <h1 data-aos="fade-left" className='text-[60px] md:text-[70px] absolute right-0 -top-10 text-[#cbab75] font_thicker'>CONTACT US</h1>
      <Toaster/>
        <h6 className='text-center text-[18px] mt-5 font-[900] font_bold '>LET US HAVE A CONVERSATION</h6>
        <h6 className='text-center text-[14px] font-[400] font_thick'>we loved  to hear from you</h6>
        <div className='md:flex items-center md:items-start justify-between my-20'>
            <div  data-aos="fade-up" className='md:mr-5 md:w-[60%]'>
                <h6 className='text-[14px] mb-2 text-left font_thick'><span className='font-black font_bold'> Unit 1 :</span> C/o Sri Hanuman Saw Mill, Acharya Collage Road Thammenahalli, Post, Chikkabanavara, Bengaluru, Karnataka 560090</h6>  
                <h6 className='text-[14px] mb-2 text-left font_thick'><span className='font-black font_bold'> Unit 2 : </span> 
                Off Tumkur Road, Jakkasandra, Village, Nelamangala Town, Karnataka 562123.</h6>  
                <h6 className='text-[14px] mb-2 font_thick'><span className='font-black font_bold'> Phone :</span> 09844610001</h6>  
                <h6 className='text-[14px] mb-2 font_thick'><span className='font-black font_bold'> Email :</span> wudgres@gmail.com</h6>  

                <h6 className='text-[12px] font_bold font-[900]'>Name</h6>
                <input value={data?.name} name="name" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.name && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.name}</h6>}

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Mobile</h6>
                <input value={data?.mobile} name="mobile" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.mobile && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.mobile}</h6>}

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Email</h6>
                <input value={data?.email} type='email' name="email" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.email && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Message</h6>
                <textarea value={data?.message} name="message" onChange={handleChange} className='border w-full p-1 mt-1 text-[12px] py-1.5  focus:outline-0' />
                {error?.message && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.message}</h6>}

                <h6 className='transition w-28 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#000] pt-1 px-3 cursor-pointer hover:bg-black hover:text-white font-[300] font_thick text-[13px]' onClick={submit}>CONTACT US</h6>

            </div>
            <div data-aos-delay="500" data-aos="fade-down" className='ml-0 mt-10 md:mt-0 md:ml-5'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.059655768515!2d77.4886008!3d13.0823899!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3c63ddd92533%3A0xf1090da00f123bfc!2sWudGres%20(%20Door-Plywood-Block%20Board%20Manufacturers%20)!5e0!3m2!1sen!2sin!4v1692631231499!5m2!1sen!2sin" className='w-full md:w-[600px]' height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0" style={{border:0}}></iframe>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7772.544325634563!2d77.489284!3d13.08193!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3c63ddd92533%3A0xf1090da00f123bfc!2sWudGres%20(%20Door-Plywood-Block%20Board%20Manufacturers%20)!5e0!3m2!1sen!2sin!4v1685878831439!5m2!1sen!2sin" className='w-full md:w-[600px]' height="450" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0" style={{border:0}} /> */}
            </div>
        </div>

    </div>
    </>
  )
}

export default Contact