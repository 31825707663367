import React,{useState} from 'react'
import {BsPinMap} from 'react-icons/bs'
import {IoLocationSharp} from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

function Branch() {

  const navigator = useNavigate()

  const [step,setstep] = useState(1)  

  const banglore_branch = [
    {
        id:1,
        name:'WudGres Exclusive',
        address:"#2484/L, AECS Layout, 'A' Block, Singasandra-Kudlu, Bangalore - 560068 ",
        contact:'Mr Hitesh - 9448358518 ',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:2,
        name:'WudGres Exclusive',
        address:'#39 & 40, Abbigere Main Road, Chikkabanavara Post, Kereguddadahalli, Bengaluru - 560090 ',
        contact:'Mr Lalit - 8884088822',
        location:'',
    },
    {
        id:3,
        name:'WudGres Studio,',
        address:'#2437, BSK 6th Stage, 3rd Block, Technology Road, 80ft Road, Bangalore - 560062',
        contact:' Mr Vinay - 9901133322',
        email:'atctiles@gmail.com',
        location:'',
    },
    {
        id:4,
        name:'WudGres Studio ',
        address:'No-2/2, Outer Ring Road, Mahadevpura Post, Bangalore - 560048,',
        contact:'Mr Girish - 9008362363',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875',
    },

    {
        id:5,
        name:'WudGres Studio',
        address:' #1, Indiranagar Circle, Andhrahalli Main Road, Nagasandra Post,Bangalore-560073 ',
        contact:'Mr Srinivas - 9900156048',
        location:'',
    },{
        id:6,
        name:'WudGres Exclusive',
        address:'#36, Varanasi Alias Jinkethimmahalli Village, Bidarahalli Hobli, TC Palya Main Road, Anandapura, Bengaluru - 560036',
        contact:'Mr Naveen - 9986952801, Mr Kaushik - 9164002800',
        location:'',
    },{
        id:7,
        name:'WudGres Studio',
        address:' Shop No. 4 Survey No.126/2B5, BH Road, Binnamangala, Nelamangala, Bangalore - 562123',
        contact:'Mr Babulal Patel - 98807404465',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/17,+19,+Dodda+Banaswadi+Main+Rd,+Subaiya+Reddy+Layout,+Ramamurthy+Nagar,+Bengaluru,+Karnataka+560043/@12.9665776,77.606349,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae112a211a7c1b:0x6f48f794bb284381!2m2!1d77.6591201!2d13.0138557',
    },{
        id:8,
        name:'WudGres Studio',
        address:'Near RK Farm Gate, Chandapura, Anekal Road, Marsur - 562106',
        contact:'Mr Devaraj - 9916505725',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Shree+Narayanaguru+SabhaBhavana,+CPQR%2BC2Q,+Kochi+-+Panvel+Hwy,+Varamballi,+Karnataka+576213/@13.3943548,75.6578717,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bbcbd7dd7beb5ab:0xf6bc320737655e5c!2m2!1d74.7400639!2d13.4387112',
    },{
        id:9,
        name:'WudGres Exclusive',
        address:'112/3, Nallapeta Mansion, 5th Main Road Chamrajpet, Opp: Syndicate Bank, Bangalore - 560018',
        contact:'Mr Akshar - 9035263707',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Mallathahalli,+Bengaluru,+Karnataka/@12.9335426,77.5254538,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae3e9c3a099b71:0xd577e453fab8b4be!2m2!1d77.4979653!2d12.9619761',
    },{
        id:10,
        name:'WudGres Exclusive',
        address:'Khata No 734/71, Balaji Layout, 50Ft Road,Mallathali Village, Yeshwantpura Hobli,Bangalore - 560056',
        contact:'Mb : 8951255307 / 7795539412',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Konanakunte+Cross,+Vasanthapura+Main+Rd,+Mango+Garden+Layout,+Konanakunte,+Bengaluru,+Karnataka+560078/@12.9052868,77.5753249,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae1558e8acd26b:0xb29c375602264209!2m2!1d77.5639739!2d12.8904637',
    },{
        id:11,
        name:'WudGres Exclusive',
        address:'#881, 14th Cross, 80Ft Main Road, MCECHS Layout, Dr Shivaramakaranth Nagar, Bangalore - 560077',
        contact:' Mr Manish - 9844420209',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Soraba,+Karnataka+577429/@12.9052868,77.5753249,14z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bbbfa6e2eceb135:0x85c51207922272e4!2m2!1d75.1022271!2d14.3811533',
    },{
        id:12,
        name:'WudGres Studio',
        address:' #117/2, Horamavu Agara Main Road, Horamavu Agara, Bengaluru - 560043',
        contact:'Mr Kalpesh : 9844144815',
        email:'fortunedoors123@gmail.com',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/%23A%2F3%2F23,+Behind+DYFI%2FCatmoss+Showroom,+Sattelite,+Complex,+Koppikar+Road,++%C2%A0%C2%A0Hubli+-+580020/@12.9205037,77.585819,13z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0',
    },{
        id:13,
        name:'WudGres Studio',
        address:'#19, 1st Floor, Jyoti Nagar, Ambe Bhavani Temple, Road, Near Sambhram Collage, Vidyaranyapura, Bangalore - 560097',
        contact:'Mr Deepesh - 9900166687',
        email:'labhenterprises@gmail.com',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Labh+Enterprises,+No-881,+14th+Cross+Rd,+M.C.E.C.H.S.+Layout+1st+Phase,+RK+Hegde+Nagar,+Bengaluru,+Karnataka+560077/@12.9938835,77.5472135,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae19383bac167d:0x64cca9bc64b7a1b8!2m2!1d77.6238098!2d13.0693994',
    },{
        id:14,
        name:'WudGres Exclusive',
        address:'#328, Parishrama, 60ft. Main Road, MEI Layout, Bagalagunte, Bangalore - 73',
        contact:'Mr Sagar - 9845679572',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/112%2F3,+Nallapeta+Mansion,+5th+Main+Road+Chamrajpet,++%C2%A0%C2%A0Opp:+Syndicate+Bank,+Bangalore+-+560018%C2%A0/@12.9205037,77.585819,13z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0',
    },{
        id:15,
        name:'WudGres Exclusive',
        address:'#3297, 80Ft Road, Hoskerehalli Cross, BSK 3rd Stage, Bangalore - 560085',
        contact:'Mr Srinivas - 8867745978',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/City+POINT,+1874,+Bangalore+-+Mangalore+Rd,+Ammeer+Mohalla,+Hassan+Rural,+Karnataka+573201/@12.9733613,76.6007899,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3ba5481797b264ef:0x81af495bfa697589!2m2!1d76.0983738!2d13.0014773',
    },{
        id:16,
        name:'WudGres Studio',
        address:'No.6 Cauvery Layout, Nagarabhavi Main Road, Bangalore- 560040',
        contact:'Mr Naveen - 9739049507',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Bengaluru,+Karnataka+560097/@12.9996635,77.5187532,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae22a1b9c44ef1:0xac69b58b9cdf2e09!2m2!1d77.5564981!2d13.0809029',
    },{
        id:17,
        name:'WudGres Studio',
        address:'#16, Venkattamma Complex,Doddaballapur Main Road, Rajankunte Village, Hesarghatta Hobli, Bengaluru - 560089',
        contact:'Mr Rajesh Dayani - 97426 74468',
        email:'mrinnovation2019@gmail.com',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/5th+Block+Vishweshwaraiah+Layout,+5th+Block,+SMV,+Railway+Layout,+Jnana+Ganga+Nagar,+Ullal,+Karnataka+560110/@12.9064157,77.5301736,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae3ea88261f949:0xe4503af839fee2ad!2m2!1d77.481548!2d12.958377',
    },{
        id:18,
        name:'WudGres Exclusive',
        address:' #41/1, Doddakanahalli, Sarjapura Road,Bangalore - 560035',
        contact:'Mr Kalpesh -  8884548865',
        email:'parimalahomesolutions1951@gmail.com',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/parimala+home+solutions,+60+Feet+Rd,+MEI+Employees+Housing+Colony,+Bengaluru,+Karnataka+560073/@12.9544628,77.4956651,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae23c94e266e7f:0x60fb35242b2ff78f!2m2!1d77.5036378!2d13.0605162',
    },{
        id:19,
        name:'WudGres Exclusive',
        address:'MSNagar, Kammanahalli, Bangalore - 560043',
        contact:'Mr Arvind Patel - 9845324588 / 9341112730',
        email:'vvrv132@gmail.com',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Silk+City+Honda,+Ramanagara,+SH-17,+Bangalore+Mysore+Road,+Ramnagar,+Ramnagar,+Karnataka+562159/@12.8494211,77.1937598,10z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae4e81b6c2ad51:0xc45348094c9b919a!2m2!1d77.2881962!2d12.7300102?entry=ttu',
    },{
        id:20,
        name:'WudGres Exclusive',
        address:'#73/1, New Timber Yard Layout, Mysore Road, Bangalore - 560026',
        contact:'Mr Chetan Patel - 9448053225',
        email:' ravanih@gmail.com',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Kodachadri+Chits+Pvt+Ltd,+1st+Floor,+Are+Anjaneya+Complex,+NH+206+Subhash+Nagar+Circle,+opp.+Police+station,+Gubbi,+Karnataka+572216/@13.0971919,77.1616051,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bb025819aec65eb:0xaac34e3f863c6108!2m2!1d76.9493266!2d13.3092613'
    },{
        id:21,
        name:'WudGres Exclusive',
        address:' #6, Parimala Nagar, Kanteerava Studio Main Road, Opp. Petrol Bunk, Bangalore - 560096',
        contact:'Mr Ravji D Patel - 9448245262',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/TVS+-+Maha+Automobile,+Bus+Stop,+No+596,+2nd+Stage+ShivaKumar+Swami+Extension,+Hadadi+Road+Near+Shivakumara+Swamy+Badavane,+Davanagere,+Karnataka+577004/@13.6438415,76.2322689,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bba2540828389d3:0x65222c7246dbd4a1!2m2!1d75.9229792!2d14.4382808',
    },{
        id:22,
        name:'Wudgres Studio',
        address:'#48/1,48/2, Chikkabidarikallu, Tumkur Road, Bangalore - 560073',
        contact:'Mr Dhaval Patel : 9902799099',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Karnataka+Bank+-+Shivamogga-Savalanga+Road+Branch+%26+ATM,+Siddeshwara+Krupa,+Karnataka+Bank+Building,+Savalanga+Road,+Savlanga+Rd,+Channappa+Layout,+Gandhi+Nagar,+Shivamogga,+Karnataka+577201/@13.4095786,76.0550924,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bbba8d563b149e9:0x7dbba71499fe817d!2m2!1d75.574032!2d13.941848',
    }
    
  ]  

  const tamilnadu_branch = [
    {
        id:39,
        name:'Wudgres Exlusive',
        address:'1/5, Sakthi Nagar, PN Road, Boyampalayam, Tirupur - 641602',
        contact:'Ph : 9994951143',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Kanchi+tiles+%26+sanitary,+76-B+sarvatheerthakarai,O.M.Pet+road,+Tamil+Nadu+631502/@12.7285669,78.1017533,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3a52c316d4812d6b:0x280b6ce53f1a29ca!2m2!1d79.6955098!2d12.8468998',
    },
    {
        id:40,
        name:'Wudgres Studio',
        address:' #3, Thindivanam Road, Anna Salai, Tiruvannamalai, Tamil Nadu 606601',
        contact:' Mr Suresh - 8012239178',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:41,
        name:'Wudgres Exclusive',
        address:' 295/40c Bye Pass Road, Paramathi Velur, Namakkal District - 638182',
        contact:'Mr Deepak : 9566444742',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:42,
        name:'Wudgres Exclusive',
        address:' #148/2, Chennai-Bangalore NH48, Alamelumangapuram, Vellore - 632009',
        contact:' Mr Deepak - 9442625854',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:43,
        name:'Wudgres Studio',
        address:' SF No 1A/1, Sathy Main Road, Saravanampatty (PO), Coimbatore - 641035',
        contact:'  Mr Eshwaramoorthy -  9715795559',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:44,
        name:'Wudgres Studio',
        address:'  SF No 1989, Chinna Andan Kovil Road, Karur - 639002 ',
        contact:'  Mr Harsh - 9787822818',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:45,
        name:'Wudgres Studio',
        address:'  #273, TPK Road, Vasanthnagar, Madurai, TN - 625003 ',
        contact:' Mr Balaji - 9047712345',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:46,
        name:'Wudgres Studio',
        address:' #3/187-1 ANNAMALAI NAGAR, KRISHNAGIRI ROAD, HOSUR - 635109  ',
        contact:'  Mr Tarun Patel - 9894121706',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:47,
        name:'Wudgres Studio',
        address:' 76-B Sarvatheerthakarai Oilmohammadpettai Road, Kanchipuram - 631502',
        contact:' Mr. Mohan - 9380261323',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:48,
        name:'Wudgres Exlusive',
        address:'No. 44/D Dindigul Main Road, Opp Saraswathi Rice Mill, Kallukudi, Ramjinagar Trichy - 620009',
        contact:'Mr Divyesh : 9626255820',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:49,
        name:'Wudgres Studio',
        address:' Plot No 9, 10 & 11, Cuddalore Puducherry Main Road, Cuddalore - 607001, Near Chinna Kangana Kuppam',
        contact:' Mr Ashok : 9842345151',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:50,
        name:'Wudgres Studio',
        address:'S No. 70/3A1A, Pillaiyarkuppam Bus Stop, Kumbakonam, Main Road, Kadampuliyur, Panruti Taluk, Cuddalore,Tamil Nadu - 607103',
        contact:'Mr Karthik - 8220002637',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:51,
        name:'Wudgres Studio',
        address:' #275, West Masi Street, Madurai, Tamil Nadu - 625001',
        contact:'Mr Vasantraj - 9943042910',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:52,
        name:'Wudgres Studio',
        address:'#312/2,3,4, Ground Floor to Second Floor, MBA Towers, Sekkalai Road, Near Janapriya Maligal, Karaikudi - 630002',
        contact:'Ph : 8678952910',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:53,
        name:'Wudgres Exclusive',
        address:' #1/178, F EB Colony, Gangakulam Road, Sivakasi, Virudhunagar, Tamil Nadu - 626124',
        contact:'Mr Adarsh - 7598005767',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:54,
        name:'Wudgres Exclusive',
        address:' #34, Mettupatty, Shandhai Road, Dindigul - 624002',
        contact:' Mr Akshay Patel - 7010670069',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:55,
        name:'Wudgres Studio',
        address:'Ground Floor, 468/16, 394/1,Katpadi Road, Vellore - 632007',
        contact:'Mr Vinod : 9791515280',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    {
        id:56,
        name:'Wudgres Studio',
        address:'1/8 Indira Nagar, Perundurai Main, Road, Thindal,Erode-638112',
        contact:'Office - 9843143744',
        location:'https://www.google.com/maps/dir/12.9204224,77.6208384/Gnanagiri+Rd,+Kamarajapuram+Colony,+Sivakasi,+Tamil+Nadu+626123/@11.1902574,76.8022696,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3b06cefa4121e20d:0x746f53feff6f3571!2m2!1d77.80228!2d9.4581976',
    },
    
  ]

  const goa_branch = [
    {
        id:46,
        name:'WudGres Exclusive',
        address:'Saidham Apt, Nr. Corporation, Bank Alto Porvorim, Bardez Goa 403521',
        contact:'Mr Yogesh - 9637557007',
    },
    {
        id:47,
        name:'WudGres Studio, ',
        address:'Ponda Panaji Road, Bhoma Goa - 403107,',
        contact:' Mr Tushar - 9637212603',
    },
  ]

  const rest_rka_branch = [
    {
        id:1,
        name:'WudGres Exclusive',
        address:"Dr. Radhakrishna Road, SS Puram, Tumkur - 572102 Mr Bharat - 8861208004",
        contact:'Mr Chandrakant - 9731861038',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:2,
        name:'WudGres Studio',
        address:"Next to Narayanaguru, Sabhabhavan, Bannanje, Udapi 576101",
        contact:'Mr Nithin - 9844995049',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:3,
        name:'WudGres Exlusive',
        address:"Hosabale Sagar Road, Rajeev Nagar, Halesorab ( P), Soraba - 577429",
        contact:'Mr Vishal - 9972461351',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:4,
        name:'WudGres Exlusive',
        address:"#A/3/23, Behind DYFI/Catmoss Showroom, Sattelite Complex, Koppikar Road, Hubli - 580020",
        contact:'Mr Narayan - 7676357999/9343100999',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:5,
        name:'WudGres Exlusive',
        address:"Shantha Sunder Complex, Near Suvarna Hotel, B.M. Road, Hassan - 573201",
        contact:'Mr Sridhar - 94482 56594',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:6,
        name:'WudGres Exlusive',
        address:" Hosabale Sagar Road, Rajeev Nagar, Halesorab ( P), Soraba - 577429",
        contact:'Mr Vishal - 9972461351',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:7,
        name:'WudGres Exlusive',
        address:"  Near Ashwini Circle, Yellapur Road, Sirsi - 581401",
        contact:'Mr Rakesh Patel - 9845861431',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:8,
        name:'WudGres Studio',
        address:" Sihineerukola, 2nd Cross, Mandya - 571401",
        contact:'Mr Ayub - 9844105590, Mr Aslam - 9448951235',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:9,
        name:'WudGres Exclusive',
        address:"City Point, BM Road, Hassan - 573201",
        contact:'Mr Rahul : 9916775694, Mr Rohit - 9739905819',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:10,
        name:'WudGres Exclusive',
        address:" Bypass Road, Gonikoppal, South Kodagu - 571213",
        contact:'Mr Raghu - 9480197470',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:11,
        name:'WudGres Exclusive',
        address:'#23/1, "Sri Kannadathi", Sri M.V Road, Near Lakshmi, Flour Mill, Sri Njijalingappa Layout, Davanagere-577004',
        contact:'Mr Harsha - 9900003200',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:12,
        name:'WudGres Exclusive',
        address:'Near CTL Vinayaka Nagar, BM Road, Ramanagaram - 562159',
        contact:'Mr Vinod - 9886811981',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:13,
        name:'WudGres Exclusive',
        address:'NH-206, Subhash Nagar, Gubbi - 572216',
        contact:'Mr Mahesh Patel : 9964388155',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:14,
        name:'WudGres Exclusive',
        address:' GF & FF, #596/C, Hadadi Road, Next to Maha TVS, Davangere - 577004',
        contact:'Mr Dinesh Jain - 9980339333',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:15,
        name:'WudGres Exclusive',
        address:'6713/1, Ground Floor, Shanti Arcade, Savalanga Road, Shimoga - 577201',
        contact:'Mr Praveen - M No - 9480777636',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:16,
        name:'WudGres Exclusive',
        address:' #5378/1C, S.S LAYOUT, A Block Shamnur Road, Davanagere-577004',
        contact:'Mr Vinay R -9663820302',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:17,
        name:'WudGres Exclusive',
        address:' Main Road, Near Aruna Theatre, Puttur - 574201',
        contact:'',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:18,
        name:'WudGres Exclusive',
        address:'Karenji Extension, NearKolar Grameena Bank, Malur - 563130',
        contact:'Mr Mahesh - 9964782011',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },
    {
        id:19,
        name:'WudGres Studio',
        address:' B.M. Road, Kenchanakuppe Gate, Bidadi, Ramanagara Dist - 562109',
        contact:'Mr Kanthi Patel - 7760898333',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },

    {
        id:20,
        name:'WudGres Studio',
        address:'#91/1, TS No 956/2A/1, RK Mill Road, Bapuji Nagar, Bellary - 583101',
        contact:'Mr Vishnu - 9448078104',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },

    {
        id:21,
        name:'WudGres Exclusive',
        address:'Urdigere Cross, Hanumanthapura, Koratagere, Tumkur Dist - 572129',
        contact:'r Dist - 572129, ',
        email:'adityaenterprises48@gmail.com',
        location:'https://www.google.com/maps/dir//AECS+Layout+-+A+Block,+AECS+Layout,+Singasandra,+Bengaluru,+Karnataka+560068/@12.8828657,77.5800105,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae14abafae6a1f:0xaa41983b88149b35!2m2!1d77.650051!2d12.882875?entry=ttu',
    },

  ]

  function openExternalLink(v,type){
    if(type === 'mail'){
        window.open(`mailto:${v}`);
    }
    if(type === 'map'){
        window.open(v);
    }
  }
   
   
  return (
    <>
    <div className='md:mt-0 w-screen overflow-x-hidden grid place-items-center justify-items-center text-white  bg-cover  w-full '>
     {/* <div>
       <h6 className='text-[14px] font_thick text-center fade-right'>We Make Things With Love</h6>
       <h6 className='text-[50px] text-center tracking-[20px] font_expand fade-left'>OUR BRANCH</h6>
     </div> */}
     {/* <div>
        <h6 className='text-[30px] text-center tracking-[10px] font_expand'>Our Display Center</h6>
        <h6 className='text-center tracking-wider text-gray-600 opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Branch </h6>
        </div> */}

   </div>
    <div className='relative bg-map bg-contain bg-repeat w-full px-10 sm:px-0 mx-auto md:w-8/12 py-10 my-10'>
      <h1 data-aos="fade-left" className='text-[50px] leading-tight md:text-[60px] uppercase text-[#cbab75] font_thicker'>Wudgres Display Centers</h1>

      <h6 className='text-[14px] text-left font_section1'>At WudGres Studios and WudGres Exclusive counters, Customers get to see the beautiful textures of Laminate, Veneer and Many other doors from our different collections. At these centers customers will have options to choose from over 100 doors that will be on Display. So wait not and Visit the nearest Studio.</h6>

        {/* <h6 className='text-center text-[18px] mt-10 font-[900] mb-10 font_bold fade-right'>OUR PRODUCTS AVAILABLE IN</h6> */}
        <div className='flex items-center w-6/6 justify-evenly md:w-2/6 mx-auto mt-10  fade-left'>
            {/* <h6 onClick={()=>setstep(1)} className={`text-[13px] font-[500] text-center cursor-pointer pb-1 ${step === 1 ? 'border-b-2 border-[#cbab75]' : 'border-b-2 border-white'}`}>KARNATAKA (<span className='font-[800]'>{banglore_branch?.length}</span>)</h6>         
            <h6 onClick={()=>setstep(2)} className={`text-[13px] font-[500] text-center cursor-pointer pb-1 ${step === 2 ? 'border-b-2 border-[#cbab75]' : 'border-b-2 border-white'}`}>TAMILNADU (<span className='font-[800]'>{tamilnadu_branch?.length}</span>)</h6>         
            <h6 onClick={()=>setstep(3)} className={`text-[13px] font-[500] text-center cursor-pointer pb-1 ${step === 3 ? 'border-b-2 border-[#cbab75]' : 'border-b-2 border-white'}`}>GOA (<span className='font-[800]'>{goa_branch?.length}</span>)</h6>    */}
            <h6 onClick={()=>setstep(1)} className={`text-[13px] font-[800] text-center cursor-pointer pb-1 ${step === 1 ? 'border-b-2 border-[#cbab75]' : 'border-b-2 border-white'}`}>KARNATAKA </h6>         
            <h6 onClick={()=>setstep(2)} className={`text-[13px] font-[800] text-center cursor-pointer pb-1 ${step === 2 ? 'border-b-2 border-[#cbab75]' : 'border-b-2 border-white'}`}>TAMILNADU </h6>         
            <h6 onClick={()=>setstep(3)} className={`text-[13px] font-[800] text-center cursor-pointer pb-1 ${step === 3 ? 'border-b-2 border-[#cbab75]' : 'border-b-2 border-white'}`}>GOA </h6>   
        </div>      

        <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mt-10'>
            {step === 1 &&
            <>
            {banglore_branch?.map((d)=>(
                <div data-aos="fade-up"  className='cursor-pointer  border rounded-[10px] border-slate-100 p-2 relative bg-white overflow-hidden fade-up'>
                    <IoLocationSharp size={20} className='my-4 flex  items-center justify-center w-full text-center text-[#cbab75]' /> 
                    {/* {d?.location !=='' && <BsPinMap onClick={()=>openExternalLink(d?.location,'map')} size={26} className='absolute right-0 top-0 bg-[#cbab75] p-1.5 cursor-pointer' />} */}
                    <h6 className='text-[13px]  text-center font-[600] font_bold'>{d?.name}</h6>
                    <h6 className='text-[12.5px] text-center font_thick'>{d?.address}</h6>
                    <h6 className='text-[12.5px] text-center font_thick'>{d?.contact}</h6>
                    {/* <h6 className='text-[12.5px] font_thick cursor-pointer text-sky-600' onClick={()=>openExternalLink(d?.email,'mail')}>{d?.email}</h6> */}
                </div>
            ))}
            </>}
            {step === 2 &&
            <>
            {tamilnadu_branch?.map((d)=>(
                <div data-aos="fade-up" className='border border-slate-100 p-2 relative bg-white overflow-hidden fade-up'>
                    <IoLocationSharp size={20} className='my-4 flex  items-center justify-center w-full text-center text-[#cbab75]' /> 
                    {/* {d?.location !=='' && <BsPinMap size={26} onClick={()=>openExternalLink(d?.location,'map')} className='absolute right-0 top-0 bg-[#cbab75] p-1.5 cursor-pointer' />} */}
                    <h6 className='text-[13px] text-center font-[600] font_bold'>{d?.name}</h6>
                    <h6 className='text-[12.5px] text-center font_thick'>{d?.address}</h6>
                    <h6 className='text-[12.5px] text-center font_thick'>{d?.contact}</h6>
                    {/* <h6 className='text-[12.5px] font_thick cursor-pointer text-sky-600' onClick={()=>openExternalLink(d?.email,'mail')}>{d?.email}</h6> */}
                </div>
            ))}
            </>}
            {step === 3 &&
            <>
            {goa_branch?.map((d)=>(
                <div data-aos="fade-up" className='border border-slate-100 p-2 relative bg-white overflow-hidden fade-up'>
                    <IoLocationSharp size={20} className='my-4 flex  items-center justify-center w-full text-center text-[#cbab75]' /> 
                    {/* {d?.location !=='' && <BsPinMap size={26} onClick={()=>openExternalLink(d?.location,'map')} className='absolute right-0 top-0 bg-[#cbab75] p-1.5 cursor-pointer' />} */}
                    <h6 className='text-[13px] text-center font-[600] font_bold'>{d?.name}</h6>
                    <h6 className='text-[12.5px] text-center font_thick'>{d?.address}</h6>
                    <h6 className='text-[12.5px] text-center font_thick'>{d?.contact}</h6>
                    {/* <h6 className='text-[12.5px] font_thick cursor-pointer text-sky-600' onClick={()=>openExternalLink(d?.email,'mail')}>{d?.email}</h6> */}
                </div>
            ))}
            </>}
        </div>
    </div>
    </>
  )
}

export default Branch